import styled from 'styled-components';

export const StyledPricingCard = styled.div`
  max-width: 390px;

  .pricing-card {
    position: relative;
    background: ${({ theme }) => theme.colors.wildSand};
    border-radius: 15px;
    padding: 0 30px 40px;
    margin-top: 56px;
    z-index: 0;

    &__bg {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      z-index: -1;
      opacity: 0;
      transition: 0.4s;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 15px;

        &:not([draggable]) {
          max-width: 100%;
        }
      }
    }

    &__icon {
      position: relative;
      height: 122px;
      width: 114px;
      transform: translateY(-56px);
      margin-bottom: -36px;
      z-index: 1;
    }

    &__title {
      margin-bottom: 7px;
      transition: 0.4s;
    }

    &__price {
      display: flex;
      border-bottom: 1px solid ${({ theme }) => theme.colors.iron};
      padding-bottom: 18px;
      margin-bottom: 22px;
      gap: 5px;

      &__currency {
        position: relative;
        top: 10px;
        margin-right: 5px;
        transition: 0.4s;
      }

      &__amount {
        display: inline-block;
        transition: 0.4s;
      }

      &__duration {
        position: relative;
        align-self: self-end;
        bottom: 15px;
        transition: 0.4s;
      }
    }

    &__description {
      color: ${({ theme }) => theme.colors.raven};
      text-align: left;
      margin-bottom: 27px;
    }

    &__features {
      margin-bottom: 35px;

      ul {
        padding-left: 16px;
        list-style: none;
        text-align: left;
        margin: 16px 0 0 0;

        li {
          &:not(:last-child) {
            margin-bottom: 7px;
          }
        }
      }
    }

    &__bottom {
      margin-top: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      .pricing-card {
        &__bg {
          opacity: 1;
        }

        &__title {
          p {
            color: ${({ theme }) => theme.colors.white};
          }
        }

        &__price {
          p {
            color: ${({ theme }) => theme.colors.white};
          }
          border-color: ${({ theme }) => theme.colors.iron};
        }
      }
    }
  }

  @media (max-width: 600px) {
    .pricing-card {
      padding: 0 16px 30px;
    }
  }

  @media (min-width: 425px) {
    min-width: auto;
  }

  @media (min-width: 1024px) {
    width: 320px;
  }

  @media (min-width: 1250px) {
    width: 390px;
  }
`;
