import React from 'react';

import { Alert } from 'antd';
import { PiSealWarning } from 'react-icons/pi';
import { Outlet } from 'react-router-dom';

import { MainFooter } from '@src/components/organisms/main-footer';
import { MainMenu } from '@src/components/organisms/main-menu';
import { TopMenu } from '@src/components/organisms/top-menu';
import { Heading2 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledDashboardLayout } from './styles';

const b = bemClassName('dashboard-layout');

const DashboardLayout = () => {
  return (
    <StyledDashboardLayout>
      <div className={b()}>
        <div className={b('desktop-menu')}>
          <MainMenu />
        </div>
        <div className={b('mobile-menu')}>
          <TopMenu />
        </div>
        <div className={b('container')}>
          <Alert
            message={
              <>
                <PiSealWarning />
                <Heading2>
                  Complete your onboarding to unlock personalized recommendations and a fully tailored experience,
                  including diet plans, workout routines, and progress tracking. This will help us guide you better and
                  support your fitness journey!
                </Heading2>
              </>
            }
            type="warning"
          />
          <Outlet />
        </div>
        <MainFooter />
      </div>
    </StyledDashboardLayout>
  );
};

export default DashboardLayout;
