import React from 'react';

import { block } from 'bem-cn';

import { Label2 } from '@src/theme';

import { StyledAnimatedProgressBar } from './styles';

const b = block('animated-progress-bar');

const AnimatedProgressBar = ({ progress, currentStep, totalSteps }) => (
  <StyledAnimatedProgressBar progress={progress}>
    <div className={b()}>
      <div className={b('container')}>
        <div className={b('container', 'complete')}>
          <div className={b('container', 'complete', 'liquid')} />
        </div>
      </div>

      {currentStep && totalSteps && (
        <Label2 className={b('steps')}>
          {currentStep} of {totalSteps}
        </Label2>
      )}
    </div>
  </StyledAnimatedProgressBar>
);

export default AnimatedProgressBar;
