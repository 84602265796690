import React from 'react';

import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager, ThemeProvider } from 'styled-components';

import GlobalStyle from './fonts';
import { variables } from './variables';

const Theme = ({ children }) => {
  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <ThemeProvider theme={{ colors: variables.colors, responsive: variables.responsive }}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </StyleSheetManager>
  );
};

export default Theme;
