import styled from 'styled-components';

export const StyledTagsCloud = styled.div`
  .tags-cloud {
    position: relative;
    background: ${({ theme }) => theme.colors.athensGray};
    border-radius: 20px;
    padding: 32px;

    &__title {
      margin-bottom: 24px;
    }

    &__content {
      display: flex;
      flex-wrap: wrap;

      &__item {
        width: fit-content;
        margin-right: 5px;
        margin-bottom: 10px;
      }
    }
  }
`;
