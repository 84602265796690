import styled from 'styled-components';

export const StyledIconTextLink = styled.div`
  width: fit-content;

  .icon-text-link {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ color, theme }) => (color ? color : theme.colors.raven)};
    gap: 12px;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.mainOrange};
    }
  }
`;
