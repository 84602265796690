import React from 'react';

import { Form } from 'antd';

import { FormInput } from '@src/components/atoms/form/form-input';
import { Heading15 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledSearchForm } from './styles';

const b = bemClassName('search-form');

const SearchForm = () => {
  const [form] = Form.useForm();

  const handleSearch = () => {
    // TODO: Handle submit
  };

  return (
    <StyledSearchForm>
      <div className={b()}>
        <div className={b('title')}>
          <Heading15>Search</Heading15>
        </div>
        <Form form={form} layout="vertical" onFinish={handleSearch}>
          <FormInput
            name="searchTerm"
            label="Keyword"
            rules={[{ required: true, message: 'Please input a keyword!' }]}
            form={form}
          />
        </Form>
      </div>
    </StyledSearchForm>
  );
};

export default React.memo(SearchForm);
