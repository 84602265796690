import React, { useCallback } from 'react';

import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

import { SocialButton } from '@src/components/atoms/social-button';
import { Paragraph1 } from '@src/theme';
import horizontalLogo from '@src/theme/assets/logo/logo-horizontal.png';
import { bemClassName } from '@src/utils/bem';

import { StyledFooterSocials } from './styles';

const b = bemClassName('footer-socials');

const FooterSocials = () => {
  const navigate = useNavigate();

  const handleGoToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <StyledFooterSocials>
      <div className={b('logo')}>
        <img src={horizontalLogo} alt="Active Shape" onClick={handleGoToHome} />
      </div>
      <Paragraph1>
        Explore personalized diets and workouts, track your wellness journey, and achieve your goals with our
        comprehensive tools.
      </Paragraph1>
      <div className={b('social-buttons')}>
        <SocialButton link={'https://twitter.com/'}>
          <FaTwitter />
        </SocialButton>
        <SocialButton link={'https://linkedin.com/'}>
          <FaLinkedin />
        </SocialButton>
        <SocialButton link={'https://facebook.com/'}>
          <FaFacebook />
        </SocialButton>
        <SocialButton link={'https://instagram.com/'}>
          <FaInstagram />
        </SocialButton>
      </div>
    </StyledFooterSocials>
  );
};

export default FooterSocials;
