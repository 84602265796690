import React from 'react';

import { LottieHoverPlayer } from '@src/components/atoms/lottie-hover-player';
import { Heading1, Heading2, Heading3, Paragraph1 } from '@src/theme';
import { chart, exercise, recipes, water } from '@src/theme/assets/animations';
import { lottieDefaultOptions } from '@src/utils/settings';

import { StyledOurServices } from './styles';

const OurServices = () => {
  return (
    <StyledOurServices>
      <div className="main-container our-services">
        <div className="our-services__heading">
          <Heading2>OUR SERVICES</Heading2>
          <Heading1>WELCOME TO THE ONLY PLACE YOU&apos;LL EVER NEED TO GET IN SHAPE</Heading1>
        </div>
        <div className="our-services__items">
          <div className="our-services__items__item">
            <div className="our-services__items__item__animation">
              <LottieHoverPlayer animationData={recipes} options={lottieDefaultOptions} speed={1.2} />
            </div>
            <div className="our-services__items__item__title">
              <Heading3>TASTE SENSATIONS</Heading3>
              <Paragraph1>
                Explore a treasure trove of over 5,000 meticulously curated recipes, complete with nutrition analysis,
                cooking tips, and wine pairings, for an elevated culinary experience.
              </Paragraph1>
            </div>
          </div>
          <div className="our-services__items__item">
            <div className="our-services__items__item__animation">
              <LottieHoverPlayer animationData={exercise} options={lottieDefaultOptions} speed={1.2} />
            </div>
            <div className="our-services__items__item__title">
              <Heading3>FITNESS FRONTIERS</Heading3>
              <Paragraph1>
                Discover workouts that transform: from high-intensity interval training to mindful yoga sessions, find
                your perfect fit for a healthier, stronger you.
              </Paragraph1>
            </div>
          </div>
          <div className="our-services__items__item">
            <div className="our-services__items__item__animation">
              <LottieHoverPlayer animationData={water} options={lottieDefaultOptions} speed={1.2} />
            </div>
            <div className="our-services__items__item__title">
              <Heading3>WELLNESS JOURNAL</Heading3>
              <Paragraph1>
                Your personal hub for tracking every aspect of your health journey, from water intake and calorie
                balance to weight progress and more, all in one place.
              </Paragraph1>
            </div>
          </div>
          <div className="our-services__items__item">
            <div className="our-services__items__item__animation">
              <LottieHoverPlayer animationData={chart} options={lottieDefaultOptions} speed={1.2} />
            </div>
            <div className="our-services__items__item__title">
              <Heading3>INSIGHTFUL ANALYTICS</Heading3>
              <Paragraph1>
                Gain valuable insights from a blend of detailed analytics, specialist advice, and AI interpretations,
                all designed to enhance your understanding and effectiveness of your health endeavors.
              </Paragraph1>
            </div>
          </div>
        </div>
      </div>
    </StyledOurServices>
  );
};

export default OurServices;
