export const NOTIFICATION_TYPES = {
  success: 'success',
  info: 'info',
  warning: 'warning',
  error: 'error',
};

export const ONBOARDING_CONFIG = {
  totalSteps: 5,
};
