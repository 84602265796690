import styled from 'styled-components';

export const StyledTagRounded = styled.span`
  .tag-rounded {
    text-transform: uppercase;
    padding: 4px 12px;
    border-radius: 50px;
    border: 1px solid ${({ theme }) => theme.colors.alto};
    background: ${({ theme }) => theme.colors.transparent};
    width: min-content;
    white-space: nowrap;
    cursor: pointer;
    transition: all ease 0.4s;

    &:hover {
      background: ${({ theme }) => theme.colors.white};
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    }
  }
`;
