import styled from 'styled-components';

import { jumpAnimation } from '@src/theme/theme-provider/animations';

export const StyledAboutMainSection = styled.div`
  .about-main-section {
    width: 100%;

    &__inner {
      margin: 120px auto 0 auto;
      max-width: 1400px;

      &__content {
        display: flex;
        flex-wrap: wrap-reverse;
        margin-bottom: 50px;
        padding: 0 40px 0 60px;
        gap: 60px;

        &__left {
          width: calc(60% - 30px);

          &__badge {
            margin-bottom: 24px;
          }

          &__title {
            margin-bottom: 36px;
          }

          &__description {
            margin-bottom: 36px;
          }
        }

        &__right {
          width: calc(40% - 30px);

          &__badge {
            display: none;
          }

          &__thumb {
            position: relative;
            display: inline-block;
            padding-right: 68px;
            height: 100%;

            img {
              border-radius: 20px;
              max-width: 100%;
              filter: drop-shadow(0px 6px 42px rgba(0, 0, 0, 0.19));

              &:first-child {
                height: 100%;
                object-fit: cover;
              }

              &:last-child {
                top: 100px;
                right: 0;
                z-index: 1;
                max-height: 40%;
                max-width: 60%;
                position: absolute;
                animation: ${jumpAnimation} 7s linear infinite;
              }
            }
          }
        }
      }
    }

    @media (max-width: 1100px) {
      &__inner {
        margin: 50px auto 0 auto;

        &__content {
          gap: 40px;

          &__left {
            padding-left: 0;
            width: 100%;

            &__badge {
              display: none;
            }
          }

          &__right {
            padding-right: 0;
            margin-bottom: 0;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            &__badge {
              display: block;
              margin-bottom: 40px;
            }

            &__thumb {
              padding-right: 60px;
              margin-left: 40px;
              max-height: 400px;
            }
          }
        }
      }
    }

    @media (max-width: 800px) {
      &__inner {
        &__content {
          padding: 0 28px;
        }
      }
    }
  }
`;
