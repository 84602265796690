import styled from 'styled-components';

export const StyledPricingPage = styled.div`
  .pricing-page {
    width: 100%;
    padding: 60px 8px;

    &__heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin: 0 auto;
      gap: 32px;
      max-width: 800px;
      margin-bottom: 50px;
    }

    &__pricing {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;
      max-width: 1250px;
      margin-bottom: 50px;
      gap: 24px;
    }

    &__plans-comparison {
      &__heading {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        gap: 32px;
        margin-bottom: 50px;
      }
    }
  }

  @media (max-width: 600px) {
    .pricing-page {
    }
  }
`;
