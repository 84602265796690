import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';

import { FlipDigitCard } from '@src/components/molecules/flip-digit-card';
import { bemClassName } from '@src/utils/bem';

import { StyledFlipCountDown } from './styles';

const b = bemClassName('flip-count-down');

const FlipCountDown = forwardRef(({ initialNumber = 0, triggerOn = '', down }, ref) => {
  const [theNumber, setTheNumber] = useState(initialNumber);
  const [nextDigits, setNextDigits] = useState(('' + initialNumber).split('').map(Number));
  const [animate, setAnimate] = useState(false);
  const isAnimating = useRef(false);

  const tick = useCallback(
    (value) => {
      if (isAnimating.current) return;
      isAnimating.current = true;

      setAnimate(false);

      const nextNumber = value ?? theNumber + (down ? -1 : 1);

      if (nextNumber >= 0) {
        const nextDigitArray = ('' + nextNumber).split('').map(Number);
        setAnimate(true);
        setNextDigits(nextDigitArray);
        setTheNumber(nextNumber);

        setTimeout(() => {
          setAnimate(false);
          isAnimating.current = false;
        }, 1000);
      }
    },
    [theNumber, down, setAnimate, setNextDigits],
  );

  useImperativeHandle(ref, () => ({
    triggerTick: tick,
  }));

  const handleClick = useCallback(() => {
    if (triggerOn === 'click' || triggerOn === 'both') {
      tick();
    }
  }, [tick, triggerOn]);

  return (
    <StyledFlipCountDown>
      <div className={b()} onClick={handleClick}>
        {nextDigits.map((digit, index) => (
          <div key={`flip-count-down-item-${index}`} className={b('item')}>
            <FlipDigitCard
              params={{
                currentDigit: digit,
                nextDigit: nextDigits[index],
                animate: animate,
              }}
            />
          </div>
        ))}
      </div>
    </StyledFlipCountDown>
  );
});

FlipCountDown.displayName = 'FlipCountDown';

export default FlipCountDown;
