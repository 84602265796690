import styled from 'styled-components';

const getButtonWidth = (width) => {
  if (!width) {
    return 'fit-content';
  } else if (typeof width === 'string') {
    return width;
  } else if (typeof width === 'number') {
    return `${width}px`;
  }

  return 'fit-content';
};

export const StyledGradientButton = styled.div`
  width: ${({ width }) => getButtonWidth(width)};

  .gradient-button {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: transparent;
    color: ${({ theme }) => theme.colors.mainOrange};
    border: 1px solid ${({ theme }) => theme.colors.mainOrange};
    border-radius: 8px;
    padding: 14px 30px;
    transition: all 0.7s ease-out;
    background: linear-gradient(
      270deg,
      rgba(58, 107, 161, 0.8),
      rgba(31, 69, 117, 0.8),
      rgba(34, 34, 34, 0),
      rgba(34, 34, 34, 0)
    );
    background-position: 1% 50%;
    background-size: 300% 300%;

    &--active,
    &:hover,
    &:focus {
      border-color: ${({ theme }) => theme.colors.mainBlue};
      color: ${({ theme }) => theme.colors.white};
      background-position: 99% 50%;
    }

    &--variant-secondary {
      color: ${({ theme }) => theme.colors.mainBlue};
      border: 1px solid ${({ theme }) => theme.colors.mainBlue};
      background: linear-gradient(
        270deg,
        rgba(235, 142, 112, 0.8),
        rgba(217, 87, 37, 0.8),
        rgba(34, 34, 34, 0),
        rgba(34, 34, 34, 0)
      );
      background-position: 1% 50%;
      background-size: 300% 300%;

      &--active,
      &:hover,
      &:focus {
        border-color: ${({ theme }) => theme.colors.mainOrange};
        color: ${({ theme }) => theme.colors.mainBlue};
      }
    }

    @media (max-width: 768px) {
      padding: 10px 24px;
    }
  }
`;
