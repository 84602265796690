import styled from 'styled-components';

export const StyledAboutOurServicesSection = styled.div`
  .about-our-services-section {
    width: 100%;
    padding: 120px 0;

    &__inner {
      margin: 0 auto;
      max-width: 1400px;

      &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 60px;
        gap: 24px;
      }

      &__swiper {
        .ant-card {
          .ant-card-cover {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              margin-top: 20px;
              font-size: 80px;
              color: ${({ theme }) => theme.colors.mainBlue};
            }
          }

          .ant-card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 24px 14px 24px;
            gap: 24px;
          }
        }
      }
    }
  }
`;
