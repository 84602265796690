import React from 'react';

import { Paragraph6 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledTagRounded } from './styles';

const b = bemClassName('tag-rounded');

const TagRounded = ({ text }) => {
  return (
    <StyledTagRounded>
      <Paragraph6 className={b()}>{text}</Paragraph6>
    </StyledTagRounded>
  );
};

export default TagRounded;
