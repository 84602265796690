import styled from 'styled-components';

export const StyledTitleBadge = styled.span`
  .title-badge {
    text-transform: uppercase;
    padding: 6px 14px;
    letter-spacing: 2px;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    width: min-content;
    white-space: nowrap;
  }
`;
