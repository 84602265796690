import styled from 'styled-components';

export const StyledSocialButton = styled.div`
  .social-button {
    width: min-content;
    height: min-content;
    padding: 10px;
    border-radius: 7px;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    transition: all ease 0.6s;
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    text-decoration: none;

    &:hover {
      background-color: ${({ theme }) => theme.colors.mainOrange};
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;
