import styled from 'styled-components';

export const StyledBmiGauge = styled.div`
  display: flex;
  justify-content: center;

  .bmi-gauge {
    display: flex;
    justify-content: center;
  }
`;
