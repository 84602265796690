import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { useOnboarding } from '@src/hooks/use-onboarding';

const OnboardingRoute = () => {
  const { isOnboardingComplete } = useOnboarding();

  return isOnboardingComplete ? <Navigate to="/user/dashboard" /> : <Outlet />;
};

export default OnboardingRoute;
