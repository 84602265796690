import React from 'react';

import { Route, Routes } from 'react-router-dom';

import { MainLayout } from '@src/layouts/main';
import { OnboardingLayout } from '@src/layouts/onboarding';
import { AboutPage } from '@src/pages/about';
import { BlogPage } from '@src/pages/blog';
import { HomePage } from '@src/pages/home';
import { LandingPage } from '@src/pages/landing';
import { OnboardingPage } from '@src/pages/onboarding';
import { PricingPage } from '@src/pages/pricing';

import { AuthenticatedRoute } from './components/molecules/authenticated-route';
import { OnboardingRoute } from './components/molecules/onboarding-route';
import { DashboardLayout } from './layouts/dashboard';
import { UserDashboardPage } from './pages/user/user-dashboard-page';

import '@src/theme/styles/style.scss';
import '@src/theme/styles/antd-overrides.scss';

const MainRoutes = () => (
  <Routes>
    <Route path="/" element={<LandingPage />} />
    <Route element={<MainLayout />}>
      <Route path="home" element={<HomePage />} />
      <Route path="pricing" element={<PricingPage />} />
      <Route path="about" element={<AboutPage />} />
      <Route path="blog" element={<BlogPage />} />
    </Route>
    <Route element={<DashboardLayout />}>
      <Route path="user" element={<AuthenticatedRoute />}>
        <Route index element={<UserDashboardPage />} />
        <Route path="dashboard" element={<UserDashboardPage />} />
      </Route>
    </Route>
    <Route element={<OnboardingLayout />}>
      <Route path="onboarding" element={<OnboardingRoute />}>
        <Route index element={<OnboardingPage />} />
      </Route>
    </Route>
  </Routes>
);

export default MainRoutes;
