import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledTextLink = styled(Link)`
  position: relative;
  text-decoration: none;
  background-image: linear-gradient(
    to right,
    ${({ theme, hoverColor }) => (hoverColor ? hoverColor : theme.colors.mainOrange)},
    ${({ theme, hoverColor }) => (hoverColor ? hoverColor : theme.colors.mainOrange)} 50%,
    ${({ theme, color }) => (color ? color : theme.colors.mainBlue)} 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    border-radius: 4px;
    background-color: ${({ theme, hoverColor }) => (hoverColor ? hoverColor : theme.colors.mainOrange)};
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    background-position: 0;

    &:before {
      transform-origin: left;
      transform: scaleX(1);
    }
  }
`;
