import React from 'react';

import { TitleBadge } from '@src/components/atoms/title-badge';
import { PlansComparisonTable } from '@src/components/organisms/plans-comparison-table';
import { pricingData } from '@src/pages/pricing/pricingData';
import { bemClassName } from '@src/utils/bem';

import { StyledAboutOurProductsSection } from './styles';

const b = bemClassName('about-our-products-section');

const AboutOurProductsSection = () => {
  return (
    <StyledAboutOurProductsSection>
      <div className={b()}>
        <div className={b('inner')}>
          <div className={b('inner', 'heading')}>
            <TitleBadge text="OUR PRODUCTS" />
          </div>
          <div className={b('inner', 'content')}>
            <PlansComparisonTable pricingData={pricingData} />
          </div>
        </div>
      </div>
    </StyledAboutOurProductsSection>
  );
};

export default AboutOurProductsSection;
