import React from 'react';

import { block } from 'bem-cn';

import { PlansComparisonTable } from '@src/components/organisms/plans-comparison-table';
import { PricingCard } from '@src/components/organisms/pricing-card';
import { Heading1, Heading2 } from '@src/theme';

import { pricingData } from './pricingData';
import { StyledPricingPage } from './styles';

const b = block('pricing-page');

const PricingPage = () => (
  <StyledPricingPage>
    <div className={b()}>
      <div className={b('heading')}>
        <Heading2>Pricing Options</Heading2>
        <Heading1>Tailored to Fit Your Needs</Heading1>
      </div>

      <div className={b('pricing')}>
        {pricingData.map(({ icon, title, price, description, features }, index) => (
          <div key={`pricing-card-${index}`} className={b('pricing', 'item')}>
            <PricingCard
              icon={<img src={icon} />}
              title={title}
              price={price}
              description={description}
              features={features}
            />
          </div>
        ))}
      </div>

      <div className={b('plans-comparison')}>
        <div className={b('plans-comparison', 'heading')}>
          <Heading1>Plan Comparison</Heading1>
        </div>
        <PlansComparisonTable pricingData={pricingData} />
      </div>
    </div>
  </StyledPricingPage>
);

export default PricingPage;
