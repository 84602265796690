import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Form } from 'antd';
import { Button, Card, Slider } from 'antd';
import PropTypes from 'prop-types';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { Heading16, Heading18, Paragraph1 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';
import { fromCmToFeet, fromKgToLbs } from '@src/utils/measure';

import { StyledMeasurementPicker } from './styles';

const b = bemClassName('measurement-picker');

const MeasurementPicker = ({
  name,
  measurementType = 'height',
  orientation = 'horizontal',
  form,
  onMeasurementChange,
}) => {
  const initialValue = measurementType === 'height' ? 170 : 70;
  const minValue = measurementType === 'height' ? 50 : 20;
  const maxValue = measurementType === 'height' ? 300 : 200;

  const [value, setValue] = useState(form.getFieldValue(name) || initialValue);

  useEffect(() => {
    form.setFieldsValue({ [name]: value });
    onMeasurementChange && onMeasurementChange(value);
  }, [form, name, onMeasurementChange, value]);

  const increaseValue = useCallback(() => {
    setValue((prevValue) => Math.min(prevValue + 1, maxValue));
  }, [maxValue]);

  const decreaseValue = useCallback(() => {
    setValue((prevValue) => Math.max(prevValue - 1, minValue));
  }, [minValue]);

  const convertedValue = useMemo(() => {
    if (measurementType === 'height') {
      return fromCmToFeet(value);
    } else {
      return fromKgToLbs(value);
    }
  }, [value, measurementType]);

  return (
    <StyledMeasurementPicker orientation={orientation}>
      <Form.Item name={name}>
        <Card className={b()}>
          <div className={b('header')}>
            <Heading18>{measurementType === 'height' ? 'Height' : 'Weight'}</Heading18>
          </div>
          <div className={b('main')}>
            <div className={b('main', 'value')}>
              <div className={b('main', 'value', 'number')}>
                <Heading16>{value}</Heading16>
              </div>
              <div className={b('main', 'value', 'unit')}>
                <Paragraph1>{measurementType === 'height' ? 'cm' : 'kg'}</Paragraph1>
              </div>
            </div>
            <div className={b('main', 'controls')}>
              <Button icon={<FaMinus />} shape="circle" onClick={decreaseValue} />
              <div className={b('main', 'controls', 'slider')}>
                <Slider
                  min={minValue}
                  max={maxValue}
                  onChange={setValue}
                  value={value}
                  vertical={orientation === 'vertical'}
                />
              </div>
              <Button icon={<FaPlus />} shape="circle" onClick={increaseValue} />
            </div>
            <div className={b('main', 'value')}>
              {measurementType === 'height' ? (
                <>
                  <div className={b('main', 'value', 'number')}>
                    <Heading16>{convertedValue.feet}</Heading16>
                  </div>
                  <div className={b('main', 'value', 'unit')}>
                    <Paragraph1>ft</Paragraph1>
                  </div>
                  <div className={b('main', 'value', 'number')}>
                    <Heading16>{convertedValue.inches}</Heading16>
                  </div>
                  <div className={b('main', 'value', 'unit')}>
                    <Paragraph1>in</Paragraph1>
                  </div>
                </>
              ) : (
                <>
                  <div className={b('main', 'value', 'number')}>
                    <Heading16>{convertedValue.lbs}</Heading16>
                  </div>
                  <div className={b('main', 'value', 'unit')}>
                    <Paragraph1>lbs</Paragraph1>
                  </div>
                </>
              )}
            </div>
          </div>
        </Card>
      </Form.Item>
    </StyledMeasurementPicker>
  );
};

MeasurementPicker.propTypes = {
  name: PropTypes.string.isRequired,
  measurementType: PropTypes.oneOf(['height', 'weight']).isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  form: PropTypes.object.isRequired,
};

export default MeasurementPicker;
