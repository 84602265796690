import styled from 'styled-components';

export const StyledOurServices = styled.div`
  margin-top: 120px;
  padding-bottom: 80px;
  width: 100%;

  .our-services {
    width: 100%;

    &__heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      margin: 0 auto;
      gap: 16px;
      max-width: 800px;
      margin-bottom: 50px;
    }

    &__items {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px 64px;

      &__item {
        max-width: 330px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        &__animation {
          margin-bottom: 32px;
          height: 280px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &__title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          text-align: center;
          gap: 10px;

          p:last-child {
            height: 110px;
          }
        }
      }
    }
  }

  @media (max-width: 1050px) {
    margin-top: 60px;
    padding-bottom: 60px;
  }
`;
