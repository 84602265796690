import styled from 'styled-components';

export const StyledAnimatedProgressBar = styled.div`
  max-width: 500px;
  width: 100%;

  .animated-progress-bar {
    width: 100%;
    height: 32px;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.colors.mainBlue};
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    &__container {
      background-color: ${({ theme }) => theme.colors.athensGray};
      width: 100%;
      height: 100%;

      &__complete {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: ${({ progress }) => progress}%;
        background-color: ${({ theme }) => theme.colors.mainOrange};
        border-radius: 8px;

        &__liquid {
          width: 50px;
          height: 50px;
          animation: rotate 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
          position: absolute;
          right: -5px;
          top: -10px;
          background-color: ${({ theme }) => theme.colors.mainOrange};
          border-radius: 40%;
        }
      }
    }

    &__steps {
      position: absolute;
      top: calc(50% - 10px);
      left: calc(50% - 30px);
      width: 60px;
      height: 10px;
      text-align: center;
      z-index: 1;
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
