import React from 'react';

import { Heading6, Heading5, Paragraph2 } from '@src/theme';

import ketoImage from '@src/theme/assets/keto-1.webp';
import { StyledPlanRow } from './styles';

const PlanRow = ({ title, subtitle, summary, downside, image }) => {
  return (
    <StyledPlanRow>
      <div className="plan-row">
        <div className="plan-row__description">
          <div className="plan-row__description__title">
            <Heading6>{title}</Heading6>
          </div>
          <div className="plan-row__description__subtitle">
            <Heading5>{subtitle}</Heading5>
          </div>
          <div className="plan-row__description__summary">
            <Paragraph2>{summary}</Paragraph2>
            <Paragraph2>{downside}</Paragraph2>
          </div>
        </div>
        <div className="plan-row__asset">
          <img src={image ? image : ketoImage} alt="" />
        </div>
      </div>
    </StyledPlanRow>
  );
};

export default PlanRow;
