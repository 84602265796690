import React from 'react';

import { Link } from 'react-router-dom';

import { bemClassName } from '@src/utils/bem';

import { StyledSocialButton } from './styles';

const b = bemClassName('social-button');

const SocialButton = ({ children, link }) => {
  return (
    <StyledSocialButton>
      <Link className={b()} to={link}>
        {children}
      </Link>
    </StyledSocialButton>
  );
};

export default SocialButton;
