import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from '@src/hooks/use-auth';

const AuthenticatedRoute = () => {
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
};

export default AuthenticatedRoute;
