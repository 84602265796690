import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getStringFromLocalStorage } from '@src/utils/local-storage';

export const usersApi = createApi({
  reducerPath: 'usersQueries',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getStringFromLocalStorage({ key: 'accessToken' });

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProfile: builder.query({
      query: () => 'users/profile',
      providesTags: ['Profile'],
    }),
    upsertUser: builder.mutation({
      query: ({ id, ...body }) => ({
        url: `users/upsert/${id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Profile'],
    }),
  }),
});

export const { useGetProfileQuery, useUpsertUserMutation } = usersApi;
