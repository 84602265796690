import styled from 'styled-components';

export const StyledUserDashboardPage = styled.div`
  width: 100%;

  .user-dashboard-page {
    width: 100%;
    max-width: 1400px;
    gap: 24px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    margin: 40px auto;

    &__recipes {
      display: flex;
      flex-wrap: wrap;
      gap: 24px;

      &__recipe {
        width: 100%;
        max-width: 300px;
        background-color: white;
      }
    }
  }
`;
