import React, { useState } from 'react';

import { Button, Card, Form, Input, Select } from 'antd';
import block from 'bem-cn';

import { useSearchRecipesQuery } from '@src/store/queries/recipes';

import { StyledUserDashboardPage } from './styles';

const { Option } = Select;
const b = block('user-dashboard-page');

const UserDashboardPage = () => {
  const [filters, setFilters] = useState({
    diet: '',
    excludeIngredients: '',
    maxCalories: '',
  });

  const { data: recipes, error, isLoading } = useSearchRecipesQuery({ filters });

  const onFinish = (values) => {
    setFilters(values);
  };

  return (
    <StyledUserDashboardPage>
      <div className={b()}>
        <h1>User Dashboard Page</h1>

        <Form layout="inline" onFinish={onFinish}>
          <Form.Item name="diet" label="Diet Type">
            <Select placeholder="Select diet" style={{ width: 200 }}>
              <Option value="keto">Keto</Option>
              <Option value="vegan">Vegan</Option>
              <Option value="vegetarian">Vegetarian</Option>
              <Option value="paleo">Paleo</Option>
            </Select>
          </Form.Item>

          <Form.Item name="excludeIngredients" label="Exclude Ingredients">
            <Input placeholder="e.g., nuts, shellfish" style={{ width: 200 }} />
          </Form.Item>

          <Form.Item name="maxCalories" label="Max Calories">
            <Input placeholder="e.g., 500" style={{ width: 150 }} />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Search
            </Button>
          </Form.Item>
        </Form>

        {isLoading && <p>Loading recipes...</p>}
        {error && <p>Error fetching recipes: {error.message}</p>}

        <div className={b('recipes')}>
          {recipes &&
            recipes.results?.map((recipe, index) => (
              <div key={`recipes-recipe-${index}`} className={b('recipes', 'recipe')}>
                <Card hoverable title={recipe.title} cover={<img alt={recipe.title} src={recipe.image} />}>
                  <p>Calories: {recipe.nutrition?.nutrients?.find((n) => n.name === 'Calories')?.amount || 'N/A'}</p>
                  <p>Protein: {recipe.nutrition?.nutrients?.find((n) => n.name === 'Protein')?.amount || 'N/A'}</p>
                </Card>
              </div>
            ))}
        </div>
      </div>
    </StyledUserDashboardPage>
  );
};

export default UserDashboardPage;
