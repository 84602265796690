import React, { memo } from 'react';

import { TextLink } from '@src/components/atoms/text-link';
import { Heading9, variables } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledFooterCopyright } from './styles';

const b = bemClassName('copyright');

const FooterCopyright = () => {
  return (
    <StyledFooterCopyright>
      <div className={b()}>
        <Heading9>
          © 2024{' '}
          <TextLink color={variables.colors.white} to="/">
            Active Shape.
          </TextLink>{' '}
          All Rights Reserved.
        </Heading9>
      </div>
    </StyledFooterCopyright>
  );
};

export default memo(FooterCopyright);
