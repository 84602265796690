import React from 'react';

import { Sector } from 'recharts';

import { Text1 } from '@src/theme';

import { StyledBmiGaugeActiveShape } from './styles';

const ActiveShape = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, RADIAN }) => {
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  const words = payload.name.split(' ');

  return (
    <StyledBmiGaugeActiveShape>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      {words.length === 1 ? (
        <Text1 x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor}>
          {payload.name}
        </Text1>
      ) : (
        words.map((word, index) => (
          <Text1 key={`word-${index}`} x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey + index * 20} textAnchor={textAnchor}>
            {word}
          </Text1>
        ))
      )}
    </StyledBmiGaugeActiveShape>
  );
};

export default ActiveShape;
