import React from 'react';

import { Outlet } from 'react-router-dom';

import { OnboardingMenu } from '@src/components/organisms/onboarding-menu';
import { TopMenu } from '@src/components/organisms/top-menu';
import { bemClassName } from '@src/utils/bem';

import { StyledOnboardingLayout } from './styles';

const b = bemClassName('onboarding-layout');

const OnboardingLayout = () => {
  return (
    <StyledOnboardingLayout>
      <div className={b()}>
        <div className={b('desktop-menu')}>
          <OnboardingMenu />
        </div>
        <div className={b('mobile-menu')}>
          <TopMenu />
        </div>
        <div className={b('container')}>
          <Outlet />
        </div>
      </div>
    </StyledOnboardingLayout>
  );
};

export default OnboardingLayout;
