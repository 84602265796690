import styled from 'styled-components';

export const StyledNoItemsFound = styled.div`
  width: fit-content;

  .no-items-found {
    width: 100%;
    max-width: 400px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
  }
`;
