import React, { useCallback, useRef } from 'react';

import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import { Autoplay, EffectCoverflow, Navigation } from 'swiper/modules';
import { Swiper as ReactSwiper, SwiperSlide } from 'swiper/react';

import { bemClassName } from '@src/utils/bem';

import { StyledSwiper } from './styles';

const b = bemClassName('as-swiper');

const Swiper = ({ slides }) => {
  const swiperRef = useRef(null);

  const goNext = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  }, []);

  const goPrev = useCallback(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  }, []);

  return (
    <StyledSwiper>
      <div className={b()}>
        <ReactSwiper
          ref={swiperRef}
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          freeMode={true}
          slidesPerView={1}
          spaceBetween={20}
          speed={3400}
          coverflowEffect={{
            rotate: 44,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          loop={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            1050: {
              slidesPerView: 3,
              spaceBetween: 40,
            },
          }}
          modules={[EffectCoverflow, Autoplay, Navigation]}
          className={b('swiper')}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index}>{slide.content}</SwiperSlide>
          ))}
        </ReactSwiper>
        <div className={b('navigation')}>
          <BsArrowLeftCircle className="swiper-button-prev" onClick={goPrev} size={32} />
          <BsArrowRightCircle className="swiper-button-next" onClick={goNext} size={32} />
        </div>
      </div>
    </StyledSwiper>
  );
};

export default Swiper;
