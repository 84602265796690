import React from 'react';

import PropTypes from 'prop-types';

import { LoadingIndicator } from '@src/components/atoms/loading-indicator';
import { bemClassName } from '@src/utils/bem';

import { StyledGradientButton } from './styles';

const b = bemClassName('gradient-button');

const GradientButton = (props) => {
  const { children, variant, active, onClick, type = 'button', loading, width = 'fit-content' } = props;

  return (
    <StyledGradientButton width={width}>
      <button className={b({ variant, active })} onClick={onClick} type={type}>
        {children}
        {loading && (
          <div className={b('icon')}>
            <LoadingIndicator size={16} />
          </div>
        )}
      </button>
    </StyledGradientButton>
  );
};

GradientButton.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  type: PropTypes.string,
  loading: PropTypes.bool,
  width: PropTypes.any,
};

export default GradientButton;
