import React from 'react';

import { StyledTextLinkColor } from './styles';

const TextLinkColor = ({ color, hoverColor, to = '#', children }) => {
  return (
    <StyledTextLinkColor color={color} hoverColor={hoverColor} to={to}>
      {children}
    </StyledTextLinkColor>
  );
};

export default TextLinkColor;
