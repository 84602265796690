import React from 'react';

import { Heading8 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledTitleBadge } from './styles';

const b = bemClassName('title-badge');

const TitleBadge = ({ text }) => {
  return (
    <StyledTitleBadge>
      <Heading8 className={b()}>{text}</Heading8>
    </StyledTitleBadge>
  );
};

export default TitleBadge;
