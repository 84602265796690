import { createGlobalStyle } from 'styled-components';

import SportsCollegeHandwrittenOtf from '../fonts/sports-college-handwritten.otf';
import SportsCollegeHandwrittenTtf from '../fonts/sports-college-handwritten.ttf';
import SportsCollegeHandwrittenWoff from '../fonts/sports-college-handwritten.woff';
import SportsCollegeOtf from '../fonts/sports-college.otf';
import SportsCollegeTtf from '../fonts/sports-college.ttf';
import SportsCollegeWoff from '../fonts/sports-college.woff';

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Sports College Handwritten';
    src: url(${SportsCollegeHandwrittenWoff}) format('woff'),
         url(${SportsCollegeHandwrittenTtf}) format('truetype'),
         url(${SportsCollegeHandwrittenOtf}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Sports College';
    src: url(${SportsCollegeWoff}) format('woff'),
         url(${SportsCollegeTtf}) format('truetype'),
         url(${SportsCollegeOtf}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
`;

export default GlobalStyle;
