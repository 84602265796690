import React from 'react';

import { Grid, Table } from 'antd';
import { block } from 'bem-cn';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { GradientButton } from '@src/components/molecules/gradient-button';
import { Heading2, Heading9, Label2, Paragraph10 } from '@src/theme';

import { StyledPlansComparisonTable } from './styles';

const b = block('plans-comparison-table');
const { useBreakpoint } = Grid;

const PlansComparisonTable = ({ pricingData }) => {
  const screens = useBreakpoint();

  const columns = [
    screens.md && {
      title: '',
      dataIndex: 'feature',
      key: 'feature',
      render: (text) => {
        return <Label2>{text}</Label2>;
      },
    },
    ...pricingData.map((plan) => ({
      title: (
        <>
          <Heading2 className={b('plan-title', { promoted: plan.isPromoted })}>{plan.title}</Heading2>
          {plan.isPromoted && (
            <div className={b('promo')}>
              {screens.md && <Heading9>Our most liked</Heading9>}
              {!screens.md && <Heading9>Most Acquired</Heading9>}
            </div>
          )}
        </>
      ),
      dataIndex: plan.id,
      key: plan.id,
      render: (value, record) => {
        if (record.colSpan) {
          return (
            <div className={b('cell-content')}>
              <Label2>{record.feature}</Label2>
            </div>
          );
        } else {
          if (record.key === 'buttons')
            return (
              <div className={b('button-container')}>
                <GradientButton>
                  {screens.md && <span>Choose this plan</span>}
                  {!screens.md && <span>Apply</span>}
                </GradientButton>
              </div>
            );

          return typeof value === 'boolean' ? (
            value ? (
              <div className={b('cell-content')}>
                <FaCheck color="green" />
              </div>
            ) : (
              <div className={b('cell-content')}>
                <FaTimes color="gray" />
              </div>
            )
          ) : (
            <div className={b('cell-content')}>
              <Paragraph10>{value}</Paragraph10>
            </div>
          );
        }
      },
      onCell: (record) => ({
        colSpan: record.colSpan ? (plan.id == 1 ? 3 : 0) : 1,
      }),
    })),
  ].filter(Boolean);

  const dataSource = [
    ...(screens.md
      ? [
          { name: 'Recipes section', colSpan: false },
          { name: 'Workout routines', colSpan: false },
          { name: 'Tracking tools', colSpan: false },
          { name: 'Reporting and insights', colSpan: false },
          { name: 'Monthly health reports in your inbox', colSpan: false },
          { name: 'Swimming workouts', colSpan: false },
        ]
      : [
          { name: 'Recipes section', colSpan: true },
          { name: 'Recipes section', colSpan: false },
          { name: 'Workout routines', colSpan: true },
          { name: 'Workout routines', colSpan: false },
          { name: 'Tracking tools', colSpan: true },
          { name: 'Tracking tools', colSpan: false },
          { name: 'Reporting and insights', colSpan: true },
          { name: 'Reporting and insights', colSpan: false },
          { name: 'Monthly health reports in your inbox', colSpan: true },
          { name: 'Monthly health reports in your inbox', colSpan: false },
          { name: 'Swimming workouts', colSpan: true },
          { name: 'Swimming workouts', colSpan: false },
        ]
    ).map((feature, index) => ({
      key: `feature-${index}`,
      feature: feature.name,
      colSpan: feature.colSpan,
      ...(!feature.colSpan
        ? pricingData.reduce((acc, plan) => {
            acc[plan.id] = plan.features.includes(feature.name);

            return acc;
          }, {})
        : {}),
    })),
    !screens.md && {
      key: 'monthlyPrice-h',
      feature: 'Monthly Price',
      colSpan: true,
    },
    {
      key: 'monthlyPrice',
      feature: 'Monthly Price',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = `$${plan.monthlyPrice.toFixed(2)}`;

        return acc;
      }, {}),
    },
    !screens.md && {
      key: 'yearlyPrice-h',
      feature: 'Yearly Price',
      colSpan: true,
    },
    {
      key: 'yearlyPrice',
      feature: 'Yearly Price',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = `$${plan.yearlyPrice.toFixed(2)}`;

        return acc;
      }, {}),
    },
    !screens.md && {
      key: 'savings-h',
      feature: 'Savings per Year',
      colSpan: true,
    },
    {
      key: 'savings',
      feature: 'Savings per Year',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = plan.savings > 0 ? `$${plan.savings.toFixed(2)}` : <FaTimes color="gray" />;

        return acc;
      }, {}),
    },
    {
      key: 'buttons',
      feature: '',
      ...pricingData.reduce((acc, plan) => {
        acc[plan.id] = true;

        return acc;
      }, {}),
    },
  ].filter(Boolean);

  return (
    <StyledPlansComparisonTable>
      <div className={b()}>
        <Table columns={columns} dataSource={dataSource} pagination={false} rowHoverable={false} />
      </div>
    </StyledPlansComparisonTable>
  );
};

export default PlansComparisonTable;
