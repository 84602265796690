import styled from 'styled-components';

export const StyledRangePicker = styled.div`
  .range-picker {
    width: max-content;
    display: flex;
    flex-direction: ${({ orientation, labelPosition }) => {
      if (orientation === 'vertical') {
        return labelPosition === 'left' ? 'row-reverse' : 'row';
      } else {
        return labelPosition === 'top' ? 'column-reverse' : 'column';
      }
    }};
    align-items: center;
    gap: 10px;
    margin: 0;
    padding: 0;

    &__label {
      margin: 10px 0 8px 8px;
    }

    &__control {
      display: flex;
      flex-direction: ${({ orientation }) => (orientation == 'vertical' ? 'column' : 'row')};
      align-items: center;
      gap: 8px;

      &__wrapper {
        position: relative;
        height: ${({ orientation }) => (orientation == 'vertical' ? '20rem' : '3rem')};
        width: ${({ orientation }) => (orientation == 'vertical' ? '3rem' : '20rem')};
        display: flex;
        align-items: center;
      }
    }

    input[type='range'] {
      appearance: none;
      -webkit-appearance: none;
      background-color: ${({ theme }) => theme.colors.alto};
      position: absolute;
      top: ${({ orientation }) => (orientation == 'vertical' ? '50%' : '0')};
      left: ${({ orientation }) => (orientation == 'vertical' ? '50%' : '0')};
      margin: 0;
      padding: 0;
      width: 20rem;
      height: ${({ orientation }) => (orientation == 'vertical' ? '3.5rem' : '3rem')};
      transform: ${({ orientation }) => (orientation == 'vertical' ? 'translate(-50%, -50%) rotate(-90deg)' : 'none')};
      border-radius: 1rem;
      overflow: hidden;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 1px;
        pointer-events: none;
        background-image: repeating-linear-gradient(
          to right,
          transparent,
          transparent ${({ step }) => `calc(${step}% - 1px)`},
          ${({ theme }) => theme.colors.mainBlue} ${({ step }) => `calc(${step}% - 1px)`},
          ${({ theme }) => theme.colors.mainBlue} ${({ step }) => `${step}%`}
        );
      }

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 0;
        box-shadow: -20rem 0 0 20rem ${({ theme }) => theme.colors.getTransparentColor(theme.colors.mainOrange, 0.8)};
      }

      &::-moz-range-thumb {
        border: none;
        width: 0;
        box-shadow: -20rem 0 0 20rem ${({ theme }) => theme.colors.getTransparentColor(theme.colors.mainOrange, 0.8)};
      }
    }
  }
`;
