import styled from 'styled-components';

export const StyledAboutPage = styled.div`
  .about-page {
    width: 100%;

    &__main-section {
    }

    &__our-services-section {
      margin-top: 120px;
      background-color: ${({ theme }) => theme.colors.concrete};
      clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 92%);
    }

    &__our-products-section {
      margin-top: 120px;
    }
  }
`;
