import React from 'react';

import { BiNews } from 'react-icons/bi';
import { FaWeight } from 'react-icons/fa';
import { GiCook } from 'react-icons/gi';
import { PiChartLineDownBold } from 'react-icons/pi';
import { TbTreadmill } from 'react-icons/tb';

export const services = [
  {
    icon: <GiCook />,
    title: 'Culinary Adventures',
    paragraph:
      "Dive into our expansive library of over 5,000 recipes tailored to your dietary preferences. Whether you're following a keto, vegan, or paleo diet, our recipes come with complete nutritional information, cooking tips, and even wine pairings to enhance your dining experience. Explore, experiment, and enjoy meals that cater precisely to your tastes and nutritional needs.",
  },
  {
    icon: <TbTreadmill />,
    title: 'Dynamic Workouts',
    paragraph:
      'Embark on a fitness journey that adapts to your personal goals and preferences. From adrenaline-pumping HIIT sessions to serene yoga flows, our platform offers a diverse array of workouts designed to fit into any schedule and suit any fitness level. Discover your ideal exercise routine and take the first step towards a stronger, healthier you.',
  },
  {
    icon: <FaWeight />,
    title: 'Personal Wellness Journal',
    paragraph:
      'Your health journey is uniquely yours, and our tracking tools are here to support every step of the way. Log your daily water intake, meals, exercise, weight, and more to keep a comprehensive record of your progress. By centralizing your health data, we empower you with the insights to make informed decisions about your wellness routine.',
  },
  {
    icon: <PiChartLineDownBold />,
    title: 'Insightful Analytics',
    paragraph:
      'Transform data into action with our advanced analytics. Combining AI-driven insights with expert advice, we provide a clear picture of your health progress and areas for improvement. From understanding your calorie intake to optimizing your workout effectiveness, our analytics are designed to keep you motivated and on track towards your goals.',
  },
  {
    icon: <BiNews />,
    title: 'Engaging Wellness Blog',
    paragraph:
      "Stay informed, inspired, and engaged with our regularly updated blog. Covering a wide range of topics from diet and fitness to mental well-being, our articles are crafted to provide you with valuable tips, latest trends, and motivational stories. Whether you're seeking deep dives into nutrition science or practical advice for everyday health, our blog is your go-to source for reliable information.",
  },
];
