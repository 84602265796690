import styled from 'styled-components';

export const StyledMainLayout = styled.div`
  .main-layout {
    height: 100%;

    &__mobile-menu {
      display: none;
    }

    @media (max-width: 1050px) {
      &__desktop-menu {
        display: none;
      }

      &__mobile-menu {
        display: block;
      }
    }
  }
`;
