import styled from 'styled-components';

export const StyledOnboardingMenu = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  .onboarding-menu {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};

    &__logo-container,
    &__right-section {
      width: 244px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__logo-container {
      img {
        max-width: 84%;
        max-height: 84%;
        cursor: pointer;
      }
    }

    &__center-section {
      flex: 1;
      height: 100%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 1px;
        background: ${({ theme }) => theme.colors.mercury};
      }

      &:before {
        left: 2px;
        transform: rotate(-10deg);
      }

      &:after {
        right: 2px;
        transform: rotate(10deg);
      }
    }

    &__right-section {
    }
  }

  @media (max-width: 1200px) {
    .main-menu {
      &__logo-container,
      &__right-section {
        width: 180px;
      }
    }
  }

  @media (max-width: 800px) {
  }
`;
