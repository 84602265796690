import React from 'react';

import { LottiePlayer } from '@src/components/atoms/lottie-player';
import { TitleBadge } from '@src/components/atoms/title-badge';
import { AnimatedButtonsCards } from '@src/components/molecules/animated-buttons-cards';
import { Heading7, Paragraph3 } from '@src/theme';
import { compass, telescope, watch } from '@src/theme/assets/animations';
import icecreamOneImage from '@src/theme/assets/icecream-1.webp';
import sportsOneImage from '@src/theme/assets/sports-1.webp';
import { bemClassName } from '@src/utils/bem';
import { lottieDefaultOptions } from '@src/utils/settings';

import { StyledAboutMainSection } from './styles';

const b = bemClassName('about-main-section');

const AboutMainSection = () => {
  return (
    <StyledAboutMainSection>
      <div className={b()}>
        <div className={b('inner')}>
          <div className={b('inner', 'content')}>
            <div className={b('inner', 'content', 'left')}>
              <div className={b('inner', 'content', 'left', 'badge')}>
                <TitleBadge text="ABOUT US" />
              </div>
              <div className={b('inner', 'content', 'left', 'title')}>
                <Heading7>Unlock Your Full Potential, Achieve Your Goals.</Heading7>
              </div>
              <div className={b('inner', 'content', 'left', 'description')}>
                <Paragraph3>
                  At Active Shape, we believe in a holistic approach to fitness and weight management. Unlike
                  traditional one-size-fits-all solutions, our product offers a personalized pathway to wellness,
                  integrating diverse diet plans, dynamic workout routines, and comprehensive tracking tools. We
                  understand that every journey is unique, which is why we provide the resources, advice, and analytics
                  needed to discover what works best for you. Whether you&apos;re exploring intermittent fasting,
                  embracing a vegan lifestyle, or delving into the details of your daily water intake, Active Shape is
                  your dedicated partner in achieving your health goals.
                </Paragraph3>
              </div>
              <div className={b('inner', 'content', 'left', 'pillars')}>
                <AnimatedButtonsCards
                  items={[
                    {
                      icon: <LottiePlayer animationData={compass} options={lottieDefaultOptions} />,
                      buttonText: 'Our Mission',
                      cardContent: (
                        <Paragraph3>
                          Active Shape is dedicated to transforming wellness into a personalized journey for each
                          individual. We offer a variety of evidence-based diet and workout programs tailored to meet
                          diverse needs and preferences. Our goal is to empower you to discover and engage with the
                          fitness and nutrition paths that best suit you, in a supportive environment that celebrates
                          every achievement. With our guidance and resources, we strive to light the way to a healthier,
                          more fulfilling lifestyle for everyone.
                        </Paragraph3>
                      ),
                    },
                    {
                      icon: <LottiePlayer animationData={telescope} options={lottieDefaultOptions} />,
                      buttonText: 'Our Vision',
                      cardContent: (
                        <Paragraph3>
                          Our vision at Active Shape is to redefine wellness as a dynamic journey, tailored to
                          individual needs and goals. We aspire to foster a global community, supported by technology
                          and expert insight, where personal transformation inspires collective action. Envisioning a
                          future where personalized wellness is universal, Active Shape aims to be the catalyst for
                          healthier, more fulfilling lives, pushing the envelope of what&apos;s possible through
                          continuous innovation.
                        </Paragraph3>
                      ),
                    },
                    {
                      icon: <LottiePlayer animationData={watch} options={lottieDefaultOptions} />,
                      buttonText: 'Our Goal',
                      cardContent: (
                        <Paragraph3>
                          Active Shape is committed to being a pivotal part of your wellness journey. Our goal is to
                          dismantle the complexities of health and fitness, making it accessible and actionable for
                          everyone. With personalized analytics and tailored recommendations, we&apos;re here to
                          motivate and support you at every stage. Whether it&apos;s achieving a specific fitness
                          milestone or embarking on a healthier lifestyle, our platform is designed to inspire and guide
                          you towards new heights, continually redefining personal wellness achievements.
                        </Paragraph3>
                      ),
                    },
                  ]}
                />
              </div>
            </div>
            <div className={b('inner', 'content', 'right')}>
              <div className={b('inner', 'content', 'right', 'badge')}>
                <TitleBadge text="ABOUT US" />
              </div>
              <div className={b('inner', 'content', 'right', 'thumb')}>
                <img src={sportsOneImage} alt="sportsImage" />
                <img src={icecreamOneImage} alt="icecreamImage" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledAboutMainSection>
  );
};

export default AboutMainSection;
