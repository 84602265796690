import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getStringFromLocalStorage } from '@src/utils/local-storage';

export const constantsApi = createApi({
  reducerPath: 'constantsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getStringFromLocalStorage({ key: 'accessToken' });

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllConstants: builder.query({
      query: () => 'constants/all',
    }),
  }),
});

export const { useGetAllConstantsQuery } = constantsApi;
