import styled from 'styled-components';

export const StyledLandingHeader = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;

  .landing-header {
    width: 100%;
    height: ${(props) => (props.variant === 'small' ? '300px' : '480px')};

    &__bg {
      width: 100%;
      height: 100%;
      background: ${({ theme }) => theme.colors.raven};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(${({ backgroundImage }) => backgroundImage});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        mix-blend-mode: multiply;
      }
    }
  }

  @media (max-width: 1050px) {
    .landing-header {
      &__bg {
        background: none;

        &::before {
          background-image: url(${({ mobileBackgroundImage }) => mobileBackgroundImage});
          background-position: bottom;
        }
      }
    }
  }
`;
