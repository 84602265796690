import React from 'react';

import { TagSquare } from '@src/components/atoms/tag-square';
import { Heading15 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledTagsCloud } from './styles';

const b = bemClassName('tags-cloud');

const TagsCloud = ({ title, tags }) => {
  return (
    <StyledTagsCloud>
      <div className={b()}>
        <div className={b('title')}>
          <Heading15>{title}</Heading15>
        </div>
        <div className={b('content')}>
          {tags.map((tag, index) => (
            <div key={`popular-tag-item-${index}`} className={b('content', 'item')}>
              <TagSquare text={tag} />
            </div>
          ))}
        </div>
      </div>
    </StyledTagsCloud>
  );
};

export default TagsCloud;
