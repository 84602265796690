import styled from 'styled-components';

import footerBackgroundImage from '@src/theme/assets/backgrounds/bg-footer.png';

export const StyledMainFooter = styled.div`
  width: 100%;

  .footer {
    width: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    background-image: url(${footerBackgroundImage});
    background-size: cover;
    background-position: center;

    &__main {
      width: 100%;
      padding: 0 32px;

      &__columns {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 1400px;
        gap: 100px;

        &__column {
          width: 320px;
          padding: 60px 0;

          &:nth-child(2) {
            width: 140px;
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    .footer {
      &__main {
        &__columns {
          gap: 60px;
        }
      }
    }
  }

  @media (max-width: 960px) {
    .footer {
      &__main {
        &__columns {
          gap: 0;

          &__column {
            padding: 30px 0;
            width: 100%;
            text-align: center;

            &:nth-child(2) {
              width: 140px;
            }
          }
        }
      }
    }
  }
`;
