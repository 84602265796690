import styled from 'styled-components';

export const StyledMenu = styled.div`
  width: 100%;

  .custom-menu {
    gap: 64px;
    width: 100%;
    display: flex;
    flex-direction: ${({ vertical }) => `${vertical ? 'column' : 'row'}`};
    align-items: ${({ vertical }) => `${vertical ? 'flex-start' : 'center'}`};
    justify-content: center;

    a {
      text-transform: uppercase;
    }

    &__item {
      text-align: center;

      p {
        margin: 0;
      }
    }
  }

  @media (max-width: 1150px) {
    .custom-menu {
      gap: ${({ vertical }) => `${vertical ? '20' : '50'}px`};
    }
  }
`;
