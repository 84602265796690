import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getStringFromLocalStorage } from '@src/utils/local-storage';

export const recipesApi = createApi({
  reducerPath: 'recipesQueries',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getStringFromLocalStorage({ key: 'accessToken' });

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    searchRecipes: builder.query({
      query: ({ filters }) => ({
        url: `recipes/get-recipes`,
        params: filters,
      }),
      providesTags: ['Recipes'],
    }),
    getRecipeDetails: builder.query({
      query: (id) => `recipes/${id}`,
      providesTags: ['RecipeDetails'],
    }),
  }),
});

export const { useSearchRecipesQuery, useGetRecipeDetailsQuery } = recipesApi;
