import React from 'react';

import { Heading4, Paragraph1 } from '@src/theme';

import { PlanRow } from './plan-row';
import { planItems } from './plans-data';
import { StyledPlans } from './styles';

const Plans = () => {
  return (
    <StyledPlans>
      <div className="small-container plans">
        <div className="plans__heading">
          <Heading4>FOLLOW YOUR OWN PATH</Heading4>
          <div className="plans__heading__summary">
            <Paragraph1>
              Explore diverse health and wellness plans on our platform, ranging from Keto to Vegan, with an honest and
              transparent approach. We detail the benefits and potential downsides of each plan, clarifying that
              downsides are possibilities rather than guarantees. This allows for informed decision-making tailored to
              your unique health goals and lifestyle preferences. Whether you're sticking to one plan, considering a
              switch, or combining elements from different approaches, our platform supports your journey with the
              flexibility to adapt. Embrace a personalized path to wellness, informed by comprehensive guidance and the
              freedom to choose what works best for you.
            </Paragraph1>
          </div>
        </div>
        <div className="plans__items">
          {planItems.map((plan, index) => (
            <PlanRow {...plan} key={`plan-item-${index}`} />
          ))}
        </div>
      </div>
    </StyledPlans>
  );
};

export default Plans;
