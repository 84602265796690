import React, { useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { AnimatedProgressBar } from '@src/components/atoms/animated-progress-bar';
import { LoadingIndicator } from '@src/components/atoms/loading-indicator';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { useGetProfileQuery } from '@src/store/queries/users';
import SingleLogo from '@src/theme/assets/logo/logo-vertical-v3.png';
import { bemClassName } from '@src/utils/bem';
import { ONBOARDING_CONFIG } from '@src/utils/constants';

import { StyledOnboardingMenu } from './styles';

const b = bemClassName('onboarding-menu');

const OnboardingMenu = () => {
  const { data: profileData, isFetching: isFetchingProfile } = useGetProfileQuery();

  const navigate = useNavigate();

  const goToHome = useCallback(() => {
    navigate('/');
  }, [navigate]);

  return (
    <StyledOnboardingMenu>
      <div className={b()}>
        <div className={b('logo-container')}>
          <img src={SingleLogo} />
        </div>
        <div className={b('center-section')}>
          {isFetchingProfile && <LoadingIndicator />}
          {!isFetchingProfile && (
            <AnimatedProgressBar
              progress={
                ONBOARDING_CONFIG.totalSteps > 0
                  ? ((profileData?.onboarding?.onboardingStep || 1) / ONBOARDING_CONFIG.totalSteps) * 100
                  : 0
              }
              currentStep={profileData?.onboarding?.onboardingStep || 1}
              totalSteps={ONBOARDING_CONFIG.totalSteps}
            />
          )}
        </div>
        <div className={b('right-section')}>
          <GradientButton onClick={goToHome}>HOME</GradientButton>
        </div>
      </div>
    </StyledOnboardingMenu>
  );
};

export default OnboardingMenu;
