import React, { memo } from 'react';

import { bemClassName } from '@src/utils/bem';

import { FooterCopyright } from './footer-copyright';
import { FooterMenu } from './footer-menu';
import { FooterNewsletter } from './footer-newsletter';
import { FooterSocials } from './footer-socials';
import { StyledMainFooter } from './styles';

const b = bemClassName('footer');

const MainFooter = () => {
  return (
    <StyledMainFooter>
      <footer className={b()}>
        <div className={b('main')}>
          <div className={b('main', 'columns')}>
            <div className={b('main', 'columns', 'column')}>
              <FooterSocials />
            </div>
            <div className={b('main', 'columns', 'column')}>
              <FooterMenu />
            </div>
            <div className={b('main', 'columns', 'column')}>
              <FooterNewsletter />
            </div>
          </div>
        </div>
        <FooterCopyright />
      </footer>
    </StyledMainFooter>
  );
};

export default memo(MainFooter);
