import styled from 'styled-components';

export const StyledTitleUnderline = styled.div`
  .title-underline {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      margin: 0;
    }

    hr {
      height: 3px;
      width: 42px;
      margin: 0;
      border: none;
      background: ${({ theme, color }) => (color ? color : theme.colors.alto)};
    }
  }

  @media (max-width: 960px) {
    .title-underline {
      align-items: center;
    }
  }
`;
