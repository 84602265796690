import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { LoadingIndicator } from '@src/components/atoms/loading-indicator';
import { useNotificationContext } from '@src/components/molecules/notification-context';
import { FlipCountDown } from '@src/components/organisms/flip-count-down';
import { useAuth } from '@src/hooks/use-auth';
import { useSignUpMutation } from '@src/store/queries/auth';
import { useGetAllConstantsQuery } from '@src/store/queries/constants';
import { useGetProfileQuery, usersApi, useUpsertUserMutation } from '@src/store/queries/users';
import { Heading2, Heading4, Paragraph1 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';
import { NOTIFICATION_TYPES } from '@src/utils/constants';

import { HealthFitnessGoalsForm } from './health-fitness-goals-form';
import { HealthInformationForm } from './health-information-form';
import { LifeStyleHabitsForm } from './life-style-habits-form';
import { MetricsForm } from './metrics-form';
import { PersonalInformationForm } from './personal-information-form';
import { StyledOnboardingPage } from './styles';

const b = bemClassName('onboarding-page');

const onboardingStepsComponents = {
  1: { title: 'Personal Information', Component: PersonalInformationForm },
  2: { title: 'Metrics', Component: MetricsForm },
  3: { title: 'Health And Fitness Goals', Component: HealthFitnessGoalsForm },
  4: { title: 'Lifestyle and Habits', Component: LifeStyleHabitsForm },
  5: { title: 'Health Information', Component: HealthInformationForm },
};

const OnboardingPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const flipCountDownRef = useRef();
  const notificationApi = useNotificationContext();
  const { userSignIn } = useAuth();
  const { data: allConstants } = useGetAllConstantsQuery();
  const { data: profileData, isFetching: isFetchingProfile } = useGetProfileQuery();
  const [upsertUser, { isLoading: isLoadingUpsert }] = useUpsertUserMutation();
  const [signUp, { isLoading: isLoadingSignUp }] = useSignUpMutation();

  const { title, Component } = useMemo(
    () => onboardingStepsComponents[profileData?.onboarding?.onboardingStep || 1],
    [profileData?.onboarding?.onboardingStep],
  );

  useEffect(() => {
    if (profileData?.onboarding?.finished) {
      return navigate('/user/dashboard');
    }
  }, [navigate, profileData]);

  const handleUpsertUser = useCallback(
    async (data) => {
      try {
        await upsertUser(data).unwrap();
      } catch (error) {
        notificationApi[NOTIFICATION_TYPES.error]({
          message: 'An error occurred while updating the user data, please try again or contact support',
          pauseOnHover: true,
        });
      }
    },
    [notificationApi, upsertUser],
  );

  const handleSignUp = useCallback(
    async (values) => {
      try {
        const { access_token } = await signUp(values).unwrap();
        userSignIn({ token: access_token, redirectUrl: '/onboarding' });
        dispatch(usersApi.util.invalidateTags(['Profile']));
      } catch (error) {
        notificationApi[NOTIFICATION_TYPES.error]({
          message: 'An error occurred while signing up, please try again or contact support',
          pauseOnHover: true,
        });
      }
    },
    [dispatch, notificationApi, signUp, userSignIn],
  );

  const handleStepFormSubmit = useCallback(
    async (values) => {
      if (!profileData) {
        handleSignUp(values);
      } else {
        handleUpsertUser({ id: profileData._id, ...values });
      }
    },
    [handleSignUp, handleUpsertUser, profileData],
  );

  const handleStepBack = useCallback(async () => {
    if (profileData) {
      handleUpsertUser({
        id: profileData._id,
        onboarding: {
          ...profileData.onboarding,
          onboardingStep: profileData.onboarding.onboardingStep - 1,
        },
      });
    }
  }, [handleUpsertUser, profileData]);

  return (
    <StyledOnboardingPage>
      <div className={b()}>
        <div className={b('heading')}>
          <Heading2>Onboarding</Heading2>
          <Paragraph1>
            Welcome to our fitness and weight loss platform! To provide you with a personalized experience, we&apos;ll
            collect only the essential information. This helps us tailor our services to fit your unique needs and
            preferences. Our onboarding process is quick and straightforward, ensuring we gather just enough data to
            customize your diet plans, workout routines, and other wellness activities. With your input, we can deliver
            the best advice and support to help you achieve your fitness goals and enjoy a healthier lifestyle.
            Let&apos;s get started on this journey together!
          </Paragraph1>
          {!isFetchingProfile && (
            <FlipCountDown ref={flipCountDownRef} initialNumber={profileData?.onboarding?.onboardingStep || 1} />
          )}
        </div>

        {!isFetchingProfile && (
          <div className={b('form')}>
            {title && (
              <div className={b('form', 'title')}>
                <Heading4>{title}</Heading4>
              </div>
            )}
            <Component
              onSubmit={handleStepFormSubmit}
              onBack={handleStepBack}
              profileData={profileData}
              optionsData={allConstants}
              loading={isLoadingUpsert || isLoadingSignUp}
            />
          </div>
        )}
        {isFetchingProfile && (
          <div className={b('loading')}>
            <LoadingIndicator />
          </div>
        )}
      </div>
    </StyledOnboardingPage>
  );
};

export default OnboardingPage;
