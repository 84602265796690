import styled from 'styled-components';

export const StyledMetricsForm = styled.div`
  width: 100%;

  .metrics-form {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    gap: 10px;
    width: 100%;

    &__measurements {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 20px;
      margin: 32px 0 16px 0;
    }

    &__item {
      width: 100%;
      justify-content: center;
      text-align: center;
    }

    &__bmi-description {
      width: 100%;
      text-align: center;
      margin-top: 16px;
    }

    &__submit {
      margin-top: 40px;
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }
`;
