import styled from 'styled-components';

export const StyledBlogCategoriesList = styled.div`
  position: relative;
  border-radius: 20px;
  padding: 32px;
  background: ${({ theme }) => theme.colors.athensGray};

  .categories-list {
    &__title {
      margin-bottom: 24px;
    }

    &__categories {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__item {
        &:not(:last-child) {
          border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
        }
      }
    }
  }
`;
