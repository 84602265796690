import styled from 'styled-components';

export const StyledFooterNewsletter = styled.div`
  .newsletter {
    &__heading {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 10px;
    }

    &__form {
      margin-top: 24px;

      form {
        gap: 16px;
        display: flex;
        flex-direction: column;
      }
    }
  }

  @media (max-width: 960px) {
    .newsletter {
      max-width: 400px;
      margin: 0 auto;
      text-align: center;
    }
  }
`;
