import React from 'react';

import { Heading10 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledTitleUnderline } from './styles';

const b = bemClassName('title-underline');

const TitleUnderline = ({ text, color }) => {
  return (
    <StyledTitleUnderline color={color}>
      <div className={b()}>
        <Heading10 color={color}>{text}</Heading10>
        <hr />
      </div>
    </StyledTitleUnderline>
  );
};

export default TitleUnderline;
