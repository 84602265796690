import React, { useCallback } from 'react';

import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

import { RoundedIconAnimated } from '@src/components/atoms/rounded-icon-animated';
import { bemClassName } from '@src/utils/bem';

import { StyledSimpleRoundedPaginator } from './styles';

const b = bemClassName('simple-rounded-paginator');

const SimpleRoundedPaginator = ({ pages = 0, activePage = 1, backgroundColor, onPageChange }) => {
  const previousPage = useCallback(() => {
    if (activePage > 1) {
      onPageChange?.(activePage - 1);
    }
  }, [activePage, onPageChange]);

  const nextPage = useCallback(() => {
    if (activePage < pages) {
      onPageChange?.(activePage + 1);
    }
  }, [activePage, onPageChange, pages]);

  const goToPage = useCallback(
    (goTo) => {
      onPageChange?.(goTo);
    },
    [onPageChange],
  );

  return (
    <StyledSimpleRoundedPaginator>
      <div className={b()}>
        <RoundedIconAnimated
          aria-label="Previous page"
          disabled={activePage <= 1}
          backgroundColor={backgroundColor}
          onClick={previousPage}
        >
          <FaArrowLeft />
        </RoundedIconAnimated>

        {pages > 0 &&
          Array.from({ length: pages }).map((_, index) => (
            <div key={`paginator-item-${index + 1}`} className={b('item')}>
              <RoundedIconAnimated
                active={activePage == index + 1}
                backgroundColor={backgroundColor}
                onClick={() => goToPage(index + 1)}
              >
                {index + 1}
              </RoundedIconAnimated>
            </div>
          ))}

        <RoundedIconAnimated
          aria-label="Next page"
          disabled={activePage >= pages}
          backgroundColor={backgroundColor}
          onClick={nextPage}
        >
          <FaArrowRight />
        </RoundedIconAnimated>
      </div>
    </StyledSimpleRoundedPaginator>
  );
};

export default SimpleRoundedPaginator;
