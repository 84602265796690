import styled from 'styled-components';

export const StyledFlipCountDown = styled.div`
  .flip-count-down {
    display: flex;
    gap: 10px;

    &__item {
    }
  }
`;
