import React from 'react';

import { FaArrowRight } from 'react-icons/fa';

import { IconTextLink } from '@src/components/molecules/icon-text-link';
import { Heading15 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledBlogCategoriesList } from './styles';

const b = bemClassName('categories-list');

const BlogCategoriesList = ({ title, categories }) => {
  return (
    <StyledBlogCategoriesList>
      <div className={b('title')}>
        <Heading15>{title}</Heading15>
      </div>
      <div className={b('categories')}>
        {categories.map((category) => (
          <div key={`category-item-${category.id}`} className={b('categories', 'category')}>
            <IconTextLink icon={<FaArrowRight />} text={category.text} textLink={category.textLink} />
          </div>
        ))}
      </div>
    </StyledBlogCategoriesList>
  );
};

export default BlogCategoriesList;
