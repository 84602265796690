import React from 'react';

import { bemClassName } from '@src/utils/bem';

import { StyledFlippingIconCard } from './styles';

const b = bemClassName('flipping-icon-card');

const FlippingIconCard = ({ children, backgroundColor, active }) => {
  return (
    <StyledFlippingIconCard backgroundColor={backgroundColor} active={active}>
      <div className={b()}>
        <div className={b('content')}>{children}</div>
      </div>
    </StyledFlippingIconCard>
  );
};

export default FlippingIconCard;
