import React from 'react';

import { StyledTextLink } from './styles';

const TextLink = ({ color, hoverColor, to, children }) => {
  return (
    <StyledTextLink color={color} hoverColor={hoverColor} to={to}>
      {children}
    </StyledTextLink>
  );
};

export default TextLink;
