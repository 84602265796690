import React, { memo } from 'react';

import { bemClassName } from '@src/utils/bem';

import { StyledLandingHeader } from './styles';

const b = bemClassName('landing-header');

const LandingHeader = ({ backgroundImage, mobileBackgroundImage, variant }) => {
  return (
    <StyledLandingHeader
      backgroundImage={backgroundImage}
      mobileBackgroundImage={mobileBackgroundImage}
      variant={variant}
    >
      <div className={b()}>
        <div className={b('bg')} />
      </div>
    </StyledLandingHeader>
  );
};

export default memo(LandingHeader);
