import React, { memo } from 'react';

import { bemClassName } from '@src/utils/bem';

import { StyledMainHeader } from './styles';

const b = bemClassName('main-header');

const MainHeader = ({ variant }) => {
  return (
    <StyledMainHeader variant={variant}>
      <div className={b()}>
        <div className={b('bg')} />
      </div>
    </StyledMainHeader>
  );
};

export default memo(MainHeader);
