import React from 'react';

import { LottiePlayer } from '@src/components/atoms/lottie-player';
import { Heading4 } from '@src/theme';
import { noItemsFound } from '@src/theme/assets/animations';
import { bemClassName } from '@src/utils/bem';
import { lottieDefaultOptions } from '@src/utils/settings';

import { StyledNoItemsFound } from './styles';

const b = bemClassName('no-items-found');

const NoItemsFound = () => {
  return (
    <StyledNoItemsFound>
      <div className={b()}>
        <Heading4>Sorry, no items found</Heading4>
        <LottiePlayer animationData={noItemsFound} options={lottieDefaultOptions} speed={1.2} />
      </div>
    </StyledNoItemsFound>
  );
};

export default NoItemsFound;
