import React from 'react';

import { Paragraph9 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledTagSquare } from './styles';

const b = bemClassName('tag-square');

const TagSquare = ({ text }) => {
  return (
    <StyledTagSquare>
      <Paragraph9 className={b()}>{text}</Paragraph9>
    </StyledTagSquare>
  );
};

export default TagSquare;
