import fastingImage from '@src/theme/assets/fasting-1.webp';
import ketoImage from '@src/theme/assets/keto-2.webp';
import mediterraneanImage from '@src/theme/assets/mediterranean-1.webp';
import paleoImage from '@src/theme/assets/paleo-1.webp';
import veganImage from '@src/theme/assets/vegan-1.webp';
import vegetarianImage from '@src/theme/assets/vegetarian-1.webp';

export const planItems = [
  {
    title: 'Intermittent Fasting',
    subtitle: 'Cycle Your Eating, Enhance Your Health',
    summary:
      'An eating pattern that alternates between periods of fasting and eating, focusing not just on what you eat, but when you eat. Popular methods include the 16/8 method, promoting weight loss, improving metabolic health, and potentially extending lifespan.',
    downside: 'Can be difficult to stick with and may cause hunger or fatigue during fasting periods.',
    image: fastingImage,
  },
  {
    title: 'Keto Diet',
    subtitle: 'Burn Fat Fast with Low-Carb, High-Fat Eating',
    summary:
      'A high-fat, low-carbohydrate diet designed to put the body into ketosis, where it efficiently burns fat for energy instead of carbohydrates. Popular for its effectiveness in weight loss and potential benefits in improving insulin sensitivity and reducing risk factors for heart disease.',
    downside: 'May lead to nutrient deficiencies and is challenging to maintain long-term.',
    image: ketoImage,
  },
  {
    title: 'Paleo Diet',
    subtitle: 'Eat Like Our Ancestors for Modern Health',
    summary:
      'Focuses on consuming whole foods similar to what might have been eaten during the Paleolithic era. Aims to eliminate processed foods, grains, and dairy in favor of meats, fish, nuts, leafy greens, and seeds, aiming to reduce inflammation and improve overall health.',
    downside: 'Can be expensive and restricts some nutrient-rich food groups.',
    image: paleoImage,
  },
  {
    title: 'Vegetarian Diet',
    subtitle: 'Embrace Plant Power for Your Health',
    summary:
      "The Vegetarian diet emphasizes eliminating meat and sometimes other animal products for health and ethical reasons. This diet primarily focuses on plants for food, including vegetables, fruits, whole grains, legumes, seeds, and nuts. It's associated with numerous health benefits, including a lower risk of heart disease, high blood pressure, type 2 diabetes, and certain cancers.",
    downside:
      'Careful planning is needed to avoid nutritional deficiencies, particularly in protein, iron, calcium, zinc, and vitamin B12.',
    image: vegetarianImage,
  },
  {
    title: 'Vegan Diet',
    subtitle: 'Compassionate, Plant-Based Living for All',
    summary:
      'Excludes all forms of animal exploitation and cruelty for food, clothing, or any other purpose. It emphasizes a plant-based diet rich in fruits, vegetables, legumes, and nuts. Known for its environmental and health benefits, including lower risks of heart disease, high blood pressure, and obesity.',
    downside: 'Risk of nutrient deficiencies such as vitamin B12, iron, calcium, and omega-3 fatty acids.',
    image: veganImage,
  },
  {
    title: 'Mediterranean Diet',
    subtitle: 'Savor Heart-Healthy Foods of the Mediterranean',
    summary:
      "Inspired by the traditional dietary patterns of countries around the Mediterranean Sea. It's rich in fruits, vegetables, whole grains, olive oil, and seafood. Celebrated for its benefits in reducing risks of cardiovascular disease and promoting longevity.",
    downside: 'May be less structured than some dieters prefer.',
    image: mediterraneanImage,
  },
];
