import styled from 'styled-components';

export const StyledPlanRow = styled.div`
  &:nth-child(even) {
    .plan-row {
      flex-direction: row-reverse;
    }
  }

  .plan-row {
    display: flex;
    gap: 64px;

    &__description {
      width: 60%;
      padding-top: 8px;

      &__title {
        margin-bottom: 16px;
      }

      &__subtitle {
        margin-bottom: 26px;
      }

      &__summary {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    &__asset {
      width: 40%;

      img {
        max-width: 100%;
        max-height: 100%;
        border-radius: 50px;
      }
    }
  }

  @media (max-width: 900px) {
    .plan-row {
      gap: 32px;

      &__description {
        padding: 0;
      }

      &__asset {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  @media (max-width: 680px) {
    .plan-row {
      flex-wrap: wrap;

      &__description {
        width: 100%;
      }

      &__asset {
        width: 100%;
      }
    }
  }
`;
