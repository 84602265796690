import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledTextLinkColor = styled(Link)`
  color: ${({ theme, color }) => (color ? color : theme.colors.codGray)};
  text-decoration: none;
  transition: all 0.4s ease-in-out;

  &:hover {
    color: ${({ theme, hoverColor }) => (hoverColor ? hoverColor : theme.colors.mainOrange)};
  }
`;
