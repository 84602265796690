import styled from 'styled-components';

import headerBackgroundImage from '@src/theme/assets/backgrounds/bg-4.webp';

export const StyledMainHeader = styled.div`
  width: 100%;
  z-index: 1;
  position: relative;

  .main-header {
    width: 100%;
    height: ${({ variant }) => (variant === 'small' ? '300px' : '480px')};

    &__bg {
      width: 100%;
      height: 100%;
      background: ${({ theme }) => theme.colors.raven};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url(${headerBackgroundImage});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        mix-blend-mode: multiply;
      }
    }
  }

  @media (max-width: 1050px) {
    .main-header {
      &__bg {
        &::before {
          background-image: url(${headerBackgroundImage});
          background-position: bottom;
        }
      }
    }
  }
`;
