import styled from 'styled-components';

export const StyledAnimatedButtonsCards = styled.div`
  .animated-buttons-cards {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;

    &__buttons {
      display: flex;
      gap: 32px;
    }

    &__cards {
      width: 100%;
      height: 100%;

      &__card {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 32px;
        border-radius: 6px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 12px 35px 0 rgba(16, 39, 112, 0.07);

        &__icon {
          width: 60px;
          height: 60px;
          position: absolute;
          top: -20px;
          left: -30px;
          transition: opacity 500ms ease-in-out;
        }

        &__content {
          transition: opacity 500ms ease-in-out;
        }
      }
    }
  }

  @media (max-width: 800px) {
    .animated-buttons-cards {
      gap: 64px;

      &__buttons {
        display: flex;
        gap: 10px;
      }

      &__cards {
        &__card {
          &__icon {
            top: -30px;
            left: -10px;
          }
        }
      }
    }
  }
`;
