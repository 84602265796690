import styled from 'styled-components';

export const StyledLoadingIndicator = styled.div`
  .atom-loading {
    width: ${({ size }) => size ?? '30'}px;
    height: ${({ size }) => size ?? '30'}px;

    &__ring {
      position: absolute;
      width: ${({ size }) => size ?? '30'}px;
      height: ${({ size }) => size ?? '30'}px;

      &:first-child {
        transform: skew(30deg, 20deg);
      }

      &:last-child {
        transform: skew(-30deg, -20deg) scale(-1, 1);

        svg {
          animation-delay: -0.75s;
        }
      }
    }
  }
`;
