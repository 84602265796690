import React from 'react';

import { TextLink } from '@src/components/atoms/text-link';
import { Heading11, variables } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledMenu } from './styles';

const b = bemClassName('custom-menu');

const Menu = ({ items, vertical }) => {
  const { white, mainOrange } = variables.colors;
  return (
    <StyledMenu vertical={vertical}>
      <div className={b()}>
        {items.map(({ label, content, link }, index) => (
          <div className={b('item')} key={`custom-menu-item-${index}`}>
            {label && (
              <TextLink to={link} color={white} hoverColor={mainOrange}>
                <Heading11>{label}</Heading11>
              </TextLink>
            )}
            {!!content && content}
          </div>
        ))}
      </div>
    </StyledMenu>
  );
};

export default Menu;
