import styled from 'styled-components';

export const StyledFlippingIconCard = styled.div`
  width: 122px;
  height: 114px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  .flipping-icon-card {
    background-color: ${({ theme }) => theme.colors.transparent};
    transition: 0.4s;
    cursor: pointer;

    &__content {
      background: ${({ theme }) => theme.colors.transparent};
      transform: rotateY(${({ active }) => (active ? '180deg' : '0deg')});
      transition: 0.4s;
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 0px;
      top: 0px;
      z-index: -1;
      transform: skewX(-10deg);
      border-radius: 15px;
    }

    &:before {
      background: ${({ active, theme }) => (active ? theme.colors.white : theme.colors.transparent)};
      width: 122px;
      height: 114px;
    }

    &:after {
      background: ${({ backgroundColor, theme }) => backgroundColor ?? theme.colors.mainOrange};
      width: 95px;
      height: 90px;
      top: 11px;
      left: 13px;
    }
  }
`;
