import React from 'react';

import { CloseIcon } from '@src/components/atoms/icons/close-icon';
import { Menu } from '@src/components/molecules/menu';
import { useAuth } from '@src/hooks/use-auth';
import { bemClassName } from '@src/utils/bem';

import { StyledSideMenu } from './styles';

const b = bemClassName('side-menu');

const SideMenu = ({ isOpen, onDismiss }) => {
  const { isAuthenticated } = useAuth();

  const handleClose = (e) => {
    e.stopPropagation();
    onDismiss();
  };

  return (
    <StyledSideMenu>
      <div className={b({ 'is-open': isOpen })} onClick={handleClose}>
        <div className={b('content')} onClick={(e) => e.stopPropagation()}>
          <button className={b('content', 'close-button')} onClick={handleClose}>
            <CloseIcon />
          </button>

          <Menu
            vertical
            items={[
              isAuthenticated && { label: 'Dashboard', link: '/user/dashboard' },
              { label: 'Home', link: '/' },
              { label: 'Pricing', link: '/pricing' },
              { label: 'About', link: '/about' },
              { label: 'Blog', link: '/blog' },
            ].filter(Boolean)}
          />
        </div>
      </div>
    </StyledSideMenu>
  );
};

export default SideMenu;
