import React from 'react';

import { TextLink } from '@src/components/atoms/text-link';
import { Heading11 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledHorizontalMenu } from './styles';

const b = bemClassName('horizontal-menu');

const HorizontalMenu = ({ items }) => {
  return (
    <StyledHorizontalMenu>
      <div className={b()}>
        {items.map(({ label, content, link }, index) => (
          <div className={b('item')} key={`horizontal-menu-item-${index}`}>
            {label && (
              <TextLink to={link}>
                <Heading11>{label}</Heading11>
              </TextLink>
            )}
            {!!content && content}
          </div>
        ))}
      </div>
    </StyledHorizontalMenu>
  );
};

export default HorizontalMenu;
