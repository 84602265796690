import React, { useCallback } from 'react';

import { Form } from 'antd';

import { OptionPicker } from '@src/components/atoms/form/option-picker';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { bemClassName } from '@src/utils/bem';

import { StyledHealthFitnessGoalsForm } from './styles';

const b = bemClassName('health-fitness-goals-form');

const HealthFitnessGoalsForm = ({ onSubmit, onBack, profileData, optionsData, loading }) => {
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values) => {
      onSubmit({
        onboarding: {
          ...profileData?.onboarding,
          onboardingStep: 4,
          healthAndFitnessGoals: {
            primaryGoal: values.primaryGoal,
            fitnessPreferences: values.fitnessPreferences,
            dietaryPreferences: values.dietaryPreferences,
          },
        },
      });
    },
    [onSubmit, profileData?.onboarding],
  );

  return (
    <StyledHealthFitnessGoalsForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          primaryGoal: profileData?.onboarding?.healthAndFitnessGoals?.primaryGoal || '',
          fitnessPreferences: profileData?.onboarding?.healthAndFitnessGoals?.fitnessPreferences || [],
          dietaryPreferences: profileData?.onboarding?.healthAndFitnessGoals?.dietaryPreferences || [],
        }}
      >
        <div className={b('item')}>
          <OptionPicker
            label="Primary Goal"
            name="primaryGoal"
            form={form}
            options={optionsData?.primaryGoals || []}
            required
          />
        </div>

        <div className={b('item')}>
          <OptionPicker
            label="Fitness Preferences"
            name="fitnessPreferences"
            form={form}
            options={optionsData?.fitnessPreferences || []}
            multiple
            required
          />
        </div>

        <div className={b('item')}>
          <OptionPicker
            label="Dietary Preferences"
            name="dietaryPreferences"
            form={form}
            options={optionsData?.dietaryPreferences || []}
            multiple
            required
          />
        </div>

        <div className={b('submit')}>
          <Form.Item>
            <GradientButton variant={'secondary'} loading={loading} onClick={onBack}>
              BACK
            </GradientButton>
          </Form.Item>
          <Form.Item>
            <GradientButton type={loading ? 'button' : 'submit'} loading={loading}>
              NEXT
            </GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledHealthFitnessGoalsForm>
  );
};

export default HealthFitnessGoalsForm;
