import styled from 'styled-components';

export const StyledLifeStyleHabitsForm = styled.div`
  width: 100%;

  .life-style-habits-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 24px;
    gap: 10px;
    width: 100%;

    &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: fit-content;
      padding-bottom: 15px;

      &__lottie {
        width: 260px;
        height: 260px;
      }

      &:nth-child(2) {
        .life-style-habits-form__item__lottie {
          padding-left: 16px;
        }
      }
    }

    &__submit {
      margin-top: 40px;
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }
`;
