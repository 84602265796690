import React from 'react';

import { RoundedIconAnimated } from '@src/components/atoms/rounded-icon-animated';
import { TextLinkColor } from '@src/components/atoms/text-link-color';
import { Paragraph8 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledIconTextLinkRounded } from './styles';

const b = bemClassName('icon-text-link-rounded');

const IconTextLinkRounded = ({ icon, text, link }) => {
  return (
    <StyledIconTextLinkRounded>
      <div className={b()}>
        <RoundedIconAnimated className={b('icon')}>{icon}</RoundedIconAnimated>
        <Paragraph8>
          <TextLinkColor className={b('link')} to={link}>
            {text}
          </TextLinkColor>
        </Paragraph8>
      </div>
    </StyledIconTextLinkRounded>
  );
};

export default IconTextLinkRounded;
