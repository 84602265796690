import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { getStringFromLocalStorage } from '@src/utils/local-storage';

export const postsApi = createApi({
  reducerPath: 'postsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_API_BASE_URL,
    prepareHeaders: (headers) => {
      const token = getStringFromLocalStorage({ key: 'accessToken' });

      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }

      return headers;
    },
    retry: 3,
  }),
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: (filter = { page: 1, limit: 10 }) => ({
        url: `posts`,
        method: 'GET',
        params: filter,
      }),
    }),
  }),
});

export const { useGetPostsQuery } = postsApi;
