import image from '@src/theme/assets/icecream-1.webp';

export const blogData = [
  {
    id: 1,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: "Mastering the Machines: A Beginner's Guide to Gym Equipment",
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 2,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Mastering the Gym: Essential Strategies for Maximizing Your Workouts',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 3,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Breaking the Sweat Barrier: Embrace the Challenge of Fitness at Fitmas',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 4,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: "Mastering the Machines: A Beginner's Guide to Gym Equipment",
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 5,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Mastering the Gym: Essential Strategies for Maximizing Your Workouts',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
  {
    id: 6,
    image: image,
    tags: ['JUNE 15, 2023', 'Shopping'],
    text: 'Breaking the Sweat Barrier: Embrace the Challenge of Fitness at Fitmas',
    textLink: '/blog',
    iconTextLink: '/blog',
  },
];

export const recentPostData = [
  {
    id: 1,
    image: image,
    text: 'Essential Strategies For Maximizing Your',
    textLink: '/blog',
    date: '25 JUN, 2023',
  },
  {
    id: 2,
    image: image,
    text: 'Functional Training Into Your Routine...',
    textLink: '/blog',
    date: '25 JUN, 2023',
  },
  {
    id: 3,
    image: image,
    text: 'Nutrition Tips Advice For Gym Goers',
    textLink: '/blog',
    date: '25 JUN, 2023',
  },
];

export const categoriesData = [
  {
    id: 1,
    text: 'Boxing Training',
    textLink: '/blog',
  },
  {
    id: 2,
    text: 'Body Building Class',
    textLink: '/blog',
  },
  {
    id: 3,
    text: 'Meditation Class',
    textLink: '/blog',
  },
  {
    id: 4,
    text: 'Mental Yoga Training',
    textLink: '/blog',
  },
  {
    id: 5,
    text: 'CrossFit Body Class',
    textLink: '/blog',
  },
];

export const galleryData = [image, image, image, image, image, image];

export const tagsData = [
  'ADVICE',
  'FIT',
  'AUTHOR',
  'FAMILY',
  'FITMAS',
  'HEALTH',
  'FITNESS',
  'GYM',
  'JUDGE',
  'SOLUTION',
  'TRAINING',
];
