import { configureStore } from '@reduxjs/toolkit';

import { authApi } from './queries/auth';
import { constantsApi } from './queries/constants';
import { postsApi } from './queries/posts';
import { recipesApi } from './queries/recipes';
import { usersApi } from './queries/users';

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [constantsApi.reducerPath]: constantsApi.reducer,
    [postsApi.reducerPath]: postsApi.reducer,
    [recipesApi.reducerPath]: recipesApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(constantsApi.middleware)
      .concat(postsApi.middleware)
      .concat(recipesApi.middleware)
      .concat(usersApi.middleware),
  devTools: {
    predicate: (_, { type, error }) => {
      if (error) {
        const { name, message } = error;

        if (
          type.includes('rejected') &&
          name === 'ConditionError' &&
          message === 'Aborted due to condition callback returning false.'
        ) {
          return false;
        }
      }

      return true;
    },
  },
});
