import styled from 'styled-components';

export const StyledDashboardLayout = styled.div`
  .dashboard-layout {
    height: 100%;

    &__mobile-menu {
      display: none;
    }

    &__container {
      .ant-alert-message {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        color: ${({ theme }) => theme.colors.mainOrange};

        svg {
          font-size: 20px;
        }
      }
    }

    @media (max-width: 1050px) {
      &__desktop-menu {
        display: none;
      }

      &__mobile-menu {
        display: block;
      }
    }
  }
`;
