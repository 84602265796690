import React from 'react';

import sectionBg from '@src/theme/assets/backgrounds/bg-3-irregular.png';
import { bemClassName } from '@src/utils/bem';

import { AboutMainSection } from './about-main-section';
import { AboutOurProductsSection } from './about-our-products-section';
import { AboutOurServicesSection } from './about-our-services-section';
import { StyledAboutPage } from './styles';

const b = bemClassName('about-page');

const AboutPage = () => {
  return (
    <StyledAboutPage sectionBg={sectionBg}>
      <div className={b()}>
        <div className={b('main-section')}>
          <AboutMainSection />
        </div>
        <div className={b('our-services-section')}>
          <AboutOurServicesSection />
        </div>
        <div className={b('our-products-section')}>
          <AboutOurProductsSection />
        </div>
      </div>
    </StyledAboutPage>
  );
};

export default AboutPage;
