import React from 'react';

import { Outlet } from 'react-router-dom';

import { MainFooter } from '@src/components/organisms/main-footer';
import { MainHeader } from '@src/components/organisms/main-header';
import { MainMenu } from '@src/components/organisms/main-menu';
import { TopMenu } from '@src/components/organisms/top-menu';
import { bemClassName } from '@src/utils/bem';

import { StyledMainLayout } from './styles';

const b = bemClassName('main-layout');

const MainLayout = () => {
  return (
    <StyledMainLayout>
      <div className={b()}>
        <div className={b('desktop-menu')}>
          <MainMenu />
        </div>
        <div className={b('mobile-menu')}>
          <TopMenu />
        </div>
        <MainHeader variant="small" />
        <div className={b('container')}>
          <Outlet />
        </div>
        <MainFooter />
      </div>
    </StyledMainLayout>
  );
};

export default MainLayout;
