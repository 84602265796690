import styled from 'styled-components';

export const StyledOnboardingPage = styled.div`
  width: 100%;
  padding: 60px 0;

  .onboarding-page {
    margin: 0 auto;
    max-width: 800px;

    &__heading {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      text-align: center;

      gap: 32px;
    }

    &__form {
      &__title {
        width: 100%;
        text-align: center;
        margin: 44px 0 16px 0;
      }
    }

    &__loading {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin: 44px 0 16px 0;
    }
  }
`;
