import React, { createContext, useContext } from 'react';

import { notification } from 'antd';

const NotificationContext = createContext();

// NotificationProvider component that uses Ant Design's notification hook
export const NotificationProvider = ({ children }) => {
  const [api, contextHolder] = notification.useNotification();

  return (
    <NotificationContext.Provider value={api}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

// Custom hook to use the notification API
export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
