import styled from 'styled-components';

const StyledCloseIcon = styled.div`
  .close-icon {
    color: ${({ theme }) => theme.colors.mainBlue};
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.mainOrange};
    }
  }
`;

export { StyledCloseIcon };
