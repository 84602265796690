import React, { useCallback, useState } from 'react';

import { Cell, Pie, PieChart } from 'recharts';

import { variables } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { BmiGaugeActiveShape } from './BmiGaugeActiveShape';
import { StyledBmiGauge } from './styles';

const RADIAN = Math.PI / 180;
const cx = 300;
const cy = 200;
const iR = 128;
const oR = 148;

const b = bemClassName('bmi-gauge');

const BmiGauge = ({ bmi = 0 }) => {
  const { havelockBlue, lima, ripeLemon, buttercup, monza, redBerry, mainOrange } = variables.colors;

  const data = [
    { name: 'Underweight', value: 18.5, color: havelockBlue },
    { name: 'Normal', value: 6.5, color: lima },
    { name: 'Overweight', value: 5, color: ripeLemon },
    { name: 'Obese', value: 5, color: buttercup },
    { name: 'Severely Obese', value: 5, color: monza },
    { name: 'Morbidly Obese', value: 5, color: redBerry },
  ];

  const [activeIndex, setActiveIndex] = useState(0);

  const onPieEnter = useCallback((_, index) => setActiveIndex(index), []);
  const renderActiveShape = useCallback((props) => <BmiGaugeActiveShape {...props} RADIAN={RADIAN} />, []);

  const needle = useCallback((value, data, cx, cy, iR, oR, color) => {
    let total = 0;

    data.forEach((v) => {
      total += v.value;
    });

    const ang = 180.0 * (1 - value / total);
    const length = (iR + 1.6 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={x0} cy={y0} r={r} fill={color} stroke="none" key="gauge-needle-circle" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        fill={color}
        stroke="none"
        key="gauge-needle-path"
      />,
    ];
  }, []);

  return (
    <StyledBmiGauge>
      <div className={b()}>
        <PieChart width={600} height={220} onMouseEnter={onPieEnter}>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            dataKey="value"
            startAngle={180}
            endAngle={0}
            data={data}
            cx={cx}
            cy={cy}
            innerRadius={iR}
            outerRadius={oR}
            stroke="none"
            onMouseEnter={onPieEnter}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
          {needle(bmi > 45 ? 45 : bmi, data, cx, cy, iR, oR, mainOrange)}
        </PieChart>
      </div>
    </StyledBmiGauge>
  );
};

export default BmiGauge;
