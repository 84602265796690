import pricing1 from '@src/theme/assets/icons/pricing-icon_1-1.svg';
import pricing2 from '@src/theme/assets/icons/pricing-icon_1-2.svg';
import pricing3 from '@src/theme/assets/icons/pricing-icon_1-3.svg';

export const monthPrice = 9.99;
export const sixMonthDiscount = 0.2;
export const yearDiscount = 0.3;
export const sixMonthPrice = monthPrice * 6 * (1 - sixMonthDiscount);
export const yearPrice = monthPrice * 12 * (1 - yearDiscount);

export const pricingData = [
  {
    id: 1,
    title: 'Monthly Initiate',
    price: monthPrice,
    icon: pricing1,
    description: 'Perfect for those testing the waters of a healthier lifestyle with month-to-month flexibility.',
    features: ['Recipes section', 'Workout routines', 'Tracking tools', 'Reporting and insights'],
    monthlyPrice: 29.99,
    yearlyPrice: 299.88,
    savings: 0,
    isPromoted: false,
  },
  {
    id: 2,
    title: 'Six Pack',
    price: sixMonthPrice.toFixed(2),
    icon: pricing2,
    description: 'Commit for six months and save 10%! Enjoy comprehensive access to all wellness tools and support.',
    features: ['Monthly health reports in your inbox'],
    monthlyPrice: 26.67,
    yearlyPrice: 319.98,
    savings: 60.0,
    isPromoted: true,
  },
  {
    id: 3,
    title: 'Yearly Elite',
    price: yearPrice.toFixed(2),
    icon: pricing3,
    description: 'Achieve and maintain your wellness goals with our full suite of resources, saving 20% annually.',
    features: ['Swimming workouts'],
    monthlyPrice: 24.99,
    yearlyPrice: 299.99,
    savings: 120.0,
    isPromoted: false,
  },
];

pricingData.forEach((plan, index) => {
  if (index > 0) {
    plan.features = pricingData[index - 1].features.concat(
      plan.features.filter((feature) => !pricingData[index - 1].features.includes(feature)),
    );
  }
});
