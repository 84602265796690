import styled from 'styled-components';

export const StyledBlogCard = styled.div`
  width: 100%;

  .blog-card {
    position: relative;
    display: flex;
    flex-direction: row;
    background: ${({ theme }) => theme.colors.athensGray};
    width: 100%;
    border-radius: 20px;
    padding: 16px 60px 16px 16px;
    justify-content: left;
    gap: 30px;

    &__image {
      width: 260px;

      img {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        height: 100%;
      }
    }

    &__content {
      &__tags {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 12px 0 18px 0;

        &__item {
          width: fit-content;
        }
      }

      &__title {
        margin-bottom: 22px;
      }
    }
  }

  @media (max-width: 1024px) {
    .blog-card {
      flex-direction: column;
      padding: 16px;
      align-items: center;
      justify-content: center;

      &__image {
        width: 200px;
      }

      &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__tags {
          justify-content: center;
        }

        &__title {
          text-align: center;
        }
      }
    }
  }
`;
