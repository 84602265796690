import React from 'react';

import { TextLink } from '@src/components/atoms/text-link';
import { TitleUnderline } from '@src/components/atoms/title-underline';
import { useAuth } from '@src/hooks/use-auth';
import { variables } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledFooterMenu } from './styles';

const b = bemClassName('footer-menu');

const FooterMenu = () => {
  const { isAuthenticated } = useAuth();

  return (
    <StyledFooterMenu>
      <div className={b()}>
        <TitleUnderline text="Quick Links" />

        <ul className={b('list')}>
          {isAuthenticated && (
            <li className={b('list', 'item')}>
              <TextLink color={variables.colors.white} to="/user/dashboard">
                Dashboard
              </TextLink>
            </li>
          )}
          <li className={b('list', 'item')}>
            <TextLink color={variables.colors.white} to="/">
              Home
            </TextLink>
          </li>
          <li className={b('list', 'item')}>
            <TextLink color={variables.colors.white} to="/pricing">
              Pricing
            </TextLink>
          </li>
          <li className={b('list', 'item')}>
            <TextLink color={variables.colors.white} to="/about">
              About
            </TextLink>
          </li>
          <li className={b('list', 'item')}>
            <TextLink color={variables.colors.white} to="/blog">
              Blog
            </TextLink>
          </li>
        </ul>
      </div>
    </StyledFooterMenu>
  );
};

export default FooterMenu;
