import styled from 'styled-components';

export const StyledPlansComparisonTable = styled.div`
  .plans-comparison-table {
    margin: 0 auto;
    max-width: 1250px;
    padding: 30px 0 0 0;

    &__plan-title {
      &_promoted {
        position: absolute;
        width: 100%;
        top: 24px;
        left: 0;
      }
    }

    &__promo {
      position: absolute;
      bottom: 16px;
      left: -8px;
      right: -8px;
      z-index: 1;
      margin: 0;
      padding: 8px;
      background: ${({ theme }) => theme.colors.mainOrange};
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.25);
      border-bottom: 1px solid ${({ theme }) => theme.colors.redBerry};

      &::before,
      &::after {
        content: '';
        position: absolute;
        display: block;
        width: 0px;
        height: 0px;
        border-style: solid;
        bottom: -7px;
      }

      &::before {
        border-width: 0 7px 7px 0;
        border-color: transparent ${({ theme }) => theme.colors.redBerry} transparent transparent;
        left: 0;
      }

      &::after {
        border-width: 7px 7px 0 0;
        border-color: ${({ theme }) => theme.colors.redBerry} transparent transparent transparent;
        right: 0;
      }
    }

    &__cell-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;
    }

    &__button-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    table {
      .ant-table-cell {
        border-right: 1px solid ${({ theme }) => theme.colors.alto};

        &:first-child {
          border-left: 1px solid ${({ theme }) => theme.colors.alto};
        }
      }

      thead {
        tr {
          th {
            position: relative;
            height: 128px;
            background-color: ${({ theme }) => theme.colors.alabaster};
            border-top: 1px solid ${({ theme }) => theme.colors.alto};
            text-align: center;

            &::before {
              content: none !important;
            }

            &:first-child {
              background-color: transparent;
              border-top: none;
              border-left: none !important;
              border-right: none;
            }

            &:nth-child(2) {
              border-left: 1px solid ${({ theme }) => theme.colors.alto};
              border-top-left-radius: 8px;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            text-align: center;

            &:first-child {
              border-right: 0;
            }

            &:nth-child(2) {
              border-left: 1px solid ${({ theme }) => theme.colors.alto};
            }

            &:first-child {
              text-align: left;
            }
          }

          &:last-child {
            td {
              border-bottom: 1px solid ${({ theme }) => theme.colors.alto};

              &:first-child {
                border-bottom: none;
                border-left: none;
              }

              &:nth-child(2) {
                border-bottom-left-radius: 8px;
              }

              &:last-child {
                border-bottom-right-radius: 8px;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 1280px) {
    .plans-comparison-table {
    }
  }

  @media (max-width: 768px) {
    .plans-comparison-table {
      padding: 40px 0 0 0;

      &__promo {
        bottom: 10px;
      }

      table {
        thead {
          tr {
            th {
              &:first-child {
                background-color: ${({ theme }) => theme.colors.alabaster};
                border-top: 1px solid ${({ theme }) => theme.colors.alto};
                border-left: 1px solid ${({ theme }) => theme.colors.alto} !important;
                border-top-left-radius: 8px;
                text-align: center !important;
              }

              &:nth-child(2) {
                border-top-left-radius: 0;
              }
            }
          }
        }

        tbody {
          tr {
            td {
              &.ant-table-cell {
                padding: 10px 6px;
              }

              &:first-child {
                border-right: 1px solid ${({ theme }) => theme.colors.alto} !important;
                text-align: center;
              }

              &:nth-child(2) {
                border-left: none;
              }
            }

            &:last-child {
              td {
                &:first-child {
                  border-bottom: 1px solid ${({ theme }) => theme.colors.alto} !important;
                  border-left: 1px solid ${({ theme }) => theme.colors.alto} !important;
                  border-bottom-left-radius: 8px !important;
                }

                &:nth-child(2) {
                  border-bottom-left-radius: 0;
                }
              }
            }
          }
        }
      }
    }
  }
`;
