import styled from 'styled-components';

export const StyledHealthFitnessGoalsForm = styled.div`
  width: 100%;

  .health-fitness-goals-form {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    gap: 10px;
    width: 100%;

    &__submit {
      margin-top: 40px;
      display: flex;
      gap: 20px;
      justify-content: center;
    }
  }
`;
