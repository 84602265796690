import styled from 'styled-components';

export const StyledTagSquare = styled.span`
  .tag-square {
    border: 1px solid ${({ theme }) => theme.colors.gallery};
    text-transform: uppercase;
    width: fit-content;
    height: 35px;
    padding: 0 14px;
    color: ${({ theme }) => theme.colors.raven};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    cursor: pointer;
    transition: all ease 0.4s;

    &:hover {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.mainOrange};
    }
  }
`;
