import styled from 'styled-components';

export const StyledTopMenu = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};

  .top-menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 10px 16px;
    position: relative;

    &__logo-container {
      display: flex;
      align-items: center;

      img {
        width: 200px;
        cursor: pointer;
      }

      svg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }

    &__menu-container {
      display: flex;
      gap: 32px;

      &__list {
        list-style: none;
        display: flex;
        align-items: center;
        gap: 24px;

        &__item {
          padding: 0;
        }
      }

      &__menu-button {
        transform: rotate(0);
        transition: all ease 0.4s;

        &:hover {
          transform: scale(1.1) translateZ(0);
        }
      }
    }
  }

  @media (max-width: 600px) {
    .top-menu {
      flex-wrap: wrap;
      gap: 16px;
      padding: 10px 48px;
      justify-content: center;

      &__menu-container {
        &__list {
          &__item {
            &:last-child {
              position: absolute;
              top: calc(50% - 16px);
              right: 12px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 420px) {
    .top-menu {
      padding: 10px 40px 10px 10px;
    }
  }
`;
