import React, { useCallback, useEffect, useState } from 'react';

import { Grid } from 'antd';
import { block } from 'bem-cn';

import { LottiePlayer } from '@src/components/atoms/lottie-player';
import { Modal } from '@src/components/atoms/modal';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { useNotificationContext } from '@src/components/molecules/notification-context';
import { useAuth } from '@src/hooks/use-auth';
import { useGoogleSignInMutation, useGoogleSignUpMutation, useSignInMutation } from '@src/store/queries/auth';
import { Heading11, Heading2, Heading4 } from '@src/theme';
import { login } from '@src/theme/assets/animations';
import { NOTIFICATION_TYPES } from '@src/utils/constants';
import { lottieDefaultOptions } from '@src/utils/settings';

import { LoginForm } from './login-form';
import { StyledLoginModal } from './styles';

const b = block('login-modal');

const LoginModal = ({ isOpen, onOk, onClose }) => {
  const notificationApi = useNotificationContext();
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();
  const { userSignIn } = useAuth();

  const [googleToken, setGoogleToken] = useState(false);
  const [googleUserNotFoundError, setGoogleUserNotFoundError] = useState(false);

  const [signIn, { data: signInData, isLoading: isLoadingSignIn, isError: isErrorSignIn, error: errorSignIn }] =
    useSignInMutation();

  const [
    googleSignIn,
    {
      data: googleSignInData,
      isLoading: isLoadingGoogleSignIn,
      isError: isErrorGoogleSignIn,
      error: errorGoogleSignIn,
    },
  ] = useGoogleSignInMutation();

  const [
    googleSignUp,
    {
      data: googleSignUpData,
      isLoading: isLoadingGoogleSignUp,
      isError: isErrorGoogleSignUp,
      error: errorGoogleSignUp,
    },
  ] = useGoogleSignUpMutation();

  useEffect(() => {
    if (!isErrorSignIn && signInData) {
      onClose();
      userSignIn({
        token: signInData.access_token,
        redirectUrl: signInData.data.onboarding.finished ? '/user/dashboard' : '/onboarding',
      });
    } else if (isErrorSignIn) {
      let error = 'An error occurred while processing your request. Please try again later.';

      if (errorSignIn.status == 401) {
        error = 'Invalid email or password';
      }

      notificationApi[NOTIFICATION_TYPES.error]({ message: error, pauseOnHover: true });
    }
  }, [notificationApi, isErrorSignIn, onClose, signInData, errorSignIn, userSignIn]);

  const signInWithGoogle = useCallback(
    async (googleResponse) => {
      setGoogleToken(googleResponse.credential);
      await googleSignIn({ token: googleResponse.credential });
    },
    [googleSignIn],
  );

  useEffect(() => {
    if (!isErrorGoogleSignIn && googleSignInData) {
      onClose();
      userSignIn({
        token: googleSignInData.access_token,
        redirectUrl: googleSignInData.data.onboarding.finished ? '/user/dashboard' : '/onboarding',
      });
    } else if (isErrorGoogleSignIn) {
      if (errorGoogleSignIn.status === 401) {
        notificationApi[NOTIFICATION_TYPES.error]({ message: errorGoogleSignIn.data.message, pauseOnHover: true });
      } else if (errorGoogleSignIn.status === 404) {
        setGoogleUserNotFoundError(true);
      } else {
        notificationApi[NOTIFICATION_TYPES.error]({
          message: 'An error occurred during Google sign in. Please try again.',
          pauseOnHover: true,
        });
      }
    }
  }, [notificationApi, onClose, googleSignInData, isErrorGoogleSignIn, errorGoogleSignIn, userSignIn]);

  const cancelSignUpWithGoogle = useCallback(async () => {
    setGoogleToken('');
    setGoogleUserNotFoundError(false);
  }, []);

  const signUpWithGoogle = useCallback(async () => {
    await googleSignUp({ token: googleToken });
  }, [googleToken, googleSignUp]);

  useEffect(() => {
    if (!isErrorGoogleSignUp && googleSignUpData) {
      onClose();
      userSignIn({
        token: googleSignUpData.access_token,
        redirectUrl: googleSignUpData.data.onboarding.finished ? '/user/dashboard' : '/onboarding',
      });
    } else if (isErrorGoogleSignUp) {
      setGoogleToken('');
      setGoogleUserNotFoundError(false);
    }
  }, [onClose, googleSignUpData, isErrorGoogleSignUp, errorGoogleSignUp, userSignIn]);

  return (
    <Modal
      className="login-modal-container p-0"
      isOpen={isOpen}
      onOk={onOk}
      onCancel={onClose}
      footer={null}
      width="90%"
    >
      <StyledLoginModal>
        <div className={b()}>
          <div className={b('body')}>
            {screens.md && (
              <div className={b('body', 'left-column')}>
                <div className={b('body', 'left-column', 'title')}>
                  <Heading4>Welcome Back</Heading4>
                </div>
                <div className={b('body', 'left-column', 'animation')}>
                  <LottiePlayer animationData={login} options={lottieDefaultOptions} />
                </div>
              </div>
            )}
            <div className={b('body', 'right-column')}>
              {!screens.md && (
                <div className={b('body', 'right-column', 'animation')}>
                  <LottiePlayer animationData={login} options={lottieDefaultOptions} />
                </div>
              )}

              <Heading2>Sign In</Heading2>
              {!googleUserNotFoundError && (
                <LoginForm
                  loading={isLoadingSignIn || isLoadingGoogleSignIn || isLoadingGoogleSignUp}
                  onSignIn={signIn}
                  onGoogleSignIn={signInWithGoogle}
                />
              )}
              {googleUserNotFoundError && (
                <div className={b('body', 'google-reg-column')}>
                  <Heading11>The user does not exist in Active Shape, do you want to create it now?</Heading11>

                  <div className={b('body', 'google-reg-column', 'buttons')}>
                    <GradientButton variant={'secondary'} onClick={cancelSignUpWithGoogle}>
                      No
                    </GradientButton>

                    <GradientButton loading={isLoadingGoogleSignIn} onClick={signUpWithGoogle}>
                      Yes
                    </GradientButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </StyledLoginModal>
    </Modal>
  );
};

export default LoginModal;
