import styled from 'styled-components';

export const StyledPlans = styled.div`
  width: 100%;
  padding: 80px 0;
  background-color: rgba(0, 0, 0, 0.9);

  .plans {
    width: 100%;

    &__heading {
      text-align: center;
      display: flex;
      flex-direction: column;
      max-width: 1000px;
      margin: 0 auto;
      gap: 32px;

      &__summary {
        display: flex;
        flex-direction: column;
        gap: 16px;
      }
    }

    &__items {
      padding-top: 80px;
      display: flex;
      flex-direction: column;
      gap: 100px;
    }
  }

  @media (max-width: 900px) {
    padding: 70px 0;

    .plans {
      &__heading {
        &__summary {
          gap: 16px;
        }
      }

      &__items {
        padding-top: 60px;
        gap: 60px;
      }
    }
  }

  @media (max-width: 680px) {
    padding: 60px 0;

    .plans {
      &__heading {
        gap: 24px;

        &__summary {
          gap: 16px;
        }
      }

      &__items {
        padding-top: 60px;
        gap: 60px;
      }
    }
  }
`;
