import React from 'react';

import { StyledRoundedIconAnimated } from './styles';

const RoundedIconAnimated = ({ backgroundColor, disabled = false, active = false, children, onClick }) => {
  return (
    <StyledRoundedIconAnimated backgroundColor={backgroundColor} disabled={disabled} active={active} onClick={onClick}>
      {children}
    </StyledRoundedIconAnimated>
  );
};

export default RoundedIconAnimated;
