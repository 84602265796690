import React from 'react';

import { bemClassName } from '@src/utils/bem';

import { StyledCloseIcon } from './styles';

const b = bemClassName('close-icon');

export const CloseIcon = ({ size = 20, title = 'Close', ...props }) => (
  <StyledCloseIcon>
    <div className={b()}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 50 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="img"
        aria-label={title}
        {...props}
      >
        <title>{title}</title>
        <path
          fill="currentColor"
          d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
        />
      </svg>
    </div>
  </StyledCloseIcon>
);

export default CloseIcon;
