import React from 'react';

import { Modal as AntModal } from 'antd';
import { block } from 'bem-cn';

import { StyledModal } from './styles';

const b = block('modal');

const Modal = ({ isOpen, onOk, onCancel, children, ...props }) => (
  <AntModal open={isOpen} onOk={onOk} onCancel={onCancel} {...props}>
    <StyledModal>
      <div className={b()}>{children}</div>
    </StyledModal>
  </AntModal>
);

export default Modal;
