import React from 'react';

import { HoverScaleImage } from '@src/components/atoms/hover-scale-image';
import { TagRounded } from '@src/components/atoms/tag-rounded';
import { TextLinkColor } from '@src/components/atoms/text-link-color';
import { IconTextLinkRounded } from '@src/components/molecules/icon-text-link-rounded';
import { Paragraph7 } from '@src/theme';
import { bemClassName } from '@src/utils/bem';

import { StyledBlogCard } from './styles';

const b = bemClassName('blog-card');

const BlogCard = ({ image, tags, text, textLink, icon, iconText, iconTextLink }) => {
  return (
    <StyledBlogCard>
      <div className={b()}>
        <div className={b('image')}>
          <HoverScaleImage source={image} />
        </div>
        <div className={b('content')}>
          <div className={b('content', 'tags')}>
            {tags.map((tag, index) => (
              <div key={`blog-card-tag-${index}`} className={b('content', 'tags', 'item')}>
                <TagRounded text={tag} />
              </div>
            ))}
          </div>
          <div className={b('content', 'title')}>
            <Paragraph7>
              <TextLinkColor to={textLink}>{text}</TextLinkColor>
            </Paragraph7>
          </div>

          <IconTextLinkRounded icon={icon} text={iconText} link={iconTextLink} />
        </div>
      </div>
    </StyledBlogCard>
  );
};

export default BlogCard;
