import styled from 'styled-components';

export const StyledFooterMenu = styled.div`
  .footer-menu {
    &__list {
      margin-top: 18px;
      list-style: none;
      padding: 0;
    }
  }

  @media (max-width: 960px) {
    .footer-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      &__list {
        text-align: center;
      }
    }
  }
`;
