import styled from 'styled-components';

export const StyledFooterSocials = styled.div`
  .footer-socials {
    &__logo {
      margin-bottom: 24px;

      img {
        max-width: 200px;
        cursor: pointer;
      }
    }

    &__social-buttons {
      display: flex;
      gap: 12px;
      margin-top: 24px;
    }
  }

  @media (max-width: 960px) {
    text-align: center;

    .footer-socials {
      &__social-buttons {
        display: flex;
        justify-content: center;
      }
    }
  }
`;
