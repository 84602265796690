import styled from 'styled-components';

export const StyledOptionPicker = styled.div`
  width: 100%;

  .options-picker {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    &__label {
      margin: 10px 0 8px 8px;
    }

    &__question {
      margin: 0 0 32px 8px;
    }

    &__options {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      &__item {
        height: 90px;

        input[type='checkbox'] {
          display: none;

          &:checked {
            + .options-picker__options__item__box {
              color: ${({ theme }) => theme.colors.white};
              background-color: ${({ theme }) => theme.colors.mainOrange};

              .options-picker__options__item__box__icon {
                transform: translateY(20px) translateX(80px);
                opacity: 1;
              }

              span {
                transform: translate(0, 44px);
              }
            }
          }
        }

        &__box {
          width: 180px;
          height: 90px;
          border-radius: 8px;
          background-color: ${({ theme }) => theme.colors.athensGray};
          color: ${({ theme }) => theme.colors.mainOrange};
          transition: all 250ms ease;
          will-change: transition;
          display: inline-block;
          text-align: center;
          position: relative;
          cursor: pointer;

          &:active {
            transform: translateY(10px);
          }

          &__icon {
            position: absolute;
            transform: translateY(-30px) translateX(80px);
            opacity: 0;
            transition: all 300ms ease-in-out;
            color: ${({ theme }) => theme.colors.white};
          }

          span {
            left: 0;
            right: 0;
            position: absolute;
            transform: translate(0, 30px);
            transition: all 100ms ease;
            user-select: none;
          }
        }
      }
    }
  }

  .ant-form-item {
    margin: 0;

    .ant-form-item-row {
      .ant-form-item-control {
        display: flex;
        flex-direction: column-reverse;
        gap: 16px;
      }
    }
  }
`;
