import React, { useEffect, useState } from 'react';

import { bemClassName } from '@src/utils/bem';

import { StyledFlipDigitCard } from './styles';

const b = bemClassName('flip-card');

const FlipDigitCard = ({ params: { currentDigit, nextDigit, animate, size } }) => {
  const [current, setCurrent] = useState(currentDigit);
  const [topHalf, setTopHalf] = useState(currentDigit);
  const [bottomHalf, setBottomHalf] = useState(currentDigit);

  useEffect(() => {
    if (animate) {
      setTopHalf(nextDigit);

      const timer = setTimeout(() => {
        setCurrent(nextDigit);
        setBottomHalf(nextDigit);
      }, 200);

      return () => clearTimeout(timer);
    } else {
      setCurrent(currentDigit);
      setTopHalf(currentDigit);
      setBottomHalf(currentDigit);
    }
  }, [animate, currentDigit, nextDigit]);

  return (
    <StyledFlipDigitCard current={current} next={nextDigit} size={size}>
      <div className={b({ animate })}>
        <div className={b('top')}>{topHalf}</div>
        <div className={b('bottom')}>{bottomHalf}</div>
      </div>
    </StyledFlipDigitCard>
  );
};

export default FlipDigitCard;
