import styled from 'styled-components';

export const StyledSearchForm = styled.div`
  .search-form {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 6px;
    border-radius: 20px;
    padding: 24px 24px 10px 24px;
    background: ${({ theme }) => theme.colors.athensGray};

    &__title {
      text-align: center;
      margin-bottom: 10px;
    }
  }
`;
