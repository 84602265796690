import styled from 'styled-components';

export const StyledLandingPage = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.athensGray};

  .landing-page {
    width: 100%;

    &__container {
      height: 100%;
    }

    &__mobile-menu {
      display: none;
    }

    @media (max-width: 1050px) {
      &__desktop-menu {
        display: none;
      }

      &__mobile-menu {
        display: block;
      }
    }
  }
`;
