import styled from 'styled-components';

export const StyledFormInput = styled.div`
  padding-top: 30px;

  .form-input {
    position: relative;
    display: inline-block;
    width: 100%;

    .ant-input-outlined,
    &__type,
    &__clear {
      z-index: 1;
      opacity: 0;
      transform: scale3d(1, 0, 1);
      transform-origin: 50% 100%;
      transition:
        opacity 0.3s,
        transform 0.3s;
    }

    .ant-input-outlined {
      position: absolute;
      top: 4px;
      left: 4px;
      display: block;
      border: none;
      padding: 0 60px 0 12px;
      width: calc(100% - 8px);
      height: calc(100% - 8px);
    }

    &--filled {
      .ant-input-outlined {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }

      .form-input {
        &__label {
          cursor: default;
          pointer-events: none;

          &::before {
            transform: scale3d(1, 1.5, 1);
          }

          &__content {
            transform: translate3d(-6px, -43px, 0) scale3d(0.7, 0.7, 1) translateZ(1px);
          }
        }
      }
    }

    &__type,
    &__clear {
      position: absolute;
      cursor: pointer;
      right: 11px;
      bottom: 17px;
      width: 16px;
      height: 16px;
      color: ${({ theme, opacity }) => theme.colors.getTransparentColor(theme.colors.mainBlue, opacity || 1)};

      &--showSuffix {
        opacity: 1;
        transform: scale3d(1, 1, 1);
      }
    }

    &__type {
      right: 30px;
    }

    &__label {
      width: 100%;
      padding: 0 16px;
      display: flex;
      user-select: none;
      text-align: left;
      cursor: text;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        transform-origin: 50% 100%;
        transition: transform 0.3s;
        background: ${({ theme, opacity }) => theme.colors.getTransparentColor(theme.colors.mainBlue, opacity || 1)};
      }

      &__content {
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 0;
        width: 100%;
        line-height: 1;
        transform-origin: 0% 50%;
        transition: transform 0.3s;

        &__icon {
          height: auto;
          width: auto;
          display: flex;
          align-items: center;
          padding-right: 10px;
        }
      }
    }
  }
`;
