import React, { memo, useCallback, useState } from 'react';

import { block } from 'bem-cn';

import { FlippingIconCard } from '@src/components/atoms/flipping-icon-card';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { Heading12, Heading13, Heading14, Heading6, Paragraph4 } from '@src/theme';
import PricingCardBg from '@src/theme/assets/backgrounds/bg-pricing-card.png';

import { StyledPricingCard } from './styles';

const b = block('pricing-card');

const PricingCard = ({ icon, title, price, description, features }) => {
  const [activeCard, setActiveCard] = useState(false);
  const handleMouseEnter = useCallback(() => {
    setActiveCard(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setActiveCard(false);
  }, []);

  return (
    <StyledPricingCard>
      <div className={b()} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <div className={b('bg')}>
          <img src={PricingCardBg} />
        </div>
        <div className={b('icon')}>
          <FlippingIconCard active={activeCard}>{icon}</FlippingIconCard>
        </div>

        <div className={b('title')}>
          <Heading12>{title}</Heading12>
        </div>

        <div className={b('price')}>
          <Heading14 className={b('price', 'currency')}>$</Heading14>
          <Heading13 className={b('price', 'amount')}>{price}</Heading13>
          <Heading6 className={b('price', 'duration')}>/month</Heading6>
        </div>

        <Paragraph4 className={b('description')}>{description}</Paragraph4>

        <div className={b('features')}>
          <ul>
            {features.map((feature, index) => (
              <li key={`pricing-card-feature-${index}`}>
                <Paragraph4>{feature || <span>&nbsp;</span>}</Paragraph4>
              </li>
            ))}
          </ul>
        </div>

        <div className={b('bottom')}>
          <GradientButton>Chose this plan</GradientButton>
        </div>
      </div>
    </StyledPricingCard>
  );
};

export default memo(PricingCard);
