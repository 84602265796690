import styled from 'styled-components';

export const StyledAboutOurProductsSection = styled.div`
  .about-our-products-section {
    width: 100%;
    margin-bottom: 200px;

    &__inner {
      margin: 0 auto;
      max-width: 1400px;

      &__heading {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
        gap: 24px;
      }

      &__content {
        padding: 0 16px;
      }
    }
  }
`;
