import styled from 'styled-components';

export const StyledMeasurementPicker = styled.div`
  width: 100%;
  max-width: 400px;

  .ant-form-item {
    margin-bottom: 0;
  }

  .measurement-picker {
    height: 100%;
    width: 100%;
    background-color: ${({ theme }) => theme.colors.getTransparentColor(theme.colors.mainBlue, 0.1)};

    &__header {
      margin-bottom: 20px;
      text-align: center;
    }

    &__main {
      display: flex;
      justify-content: ${({ orientation }) => (orientation === 'vertical' ? 'center' : 'flex-start')};
      flex-direction: ${({ orientation }) => (orientation === 'vertical' ? 'row' : 'column')};
      height: ${({ orientation }) => (orientation === 'vertical' ? '400px' : '100%')};

      &__value {
        display: flex;
        justify-content: center;
        align-items: ${({ orientation }) => (orientation === 'vertical' ? 'center' : 'flex-end')};
        width: 90px;
        margin: 10px 8px;
        user-select: none;

        &__number {
          margin: 0;
        }

        &__unit {
          margin: 0 4px;
          padding-bottom: 7px;
        }
      }

      &__controls {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: ${({ orientation }) => (orientation === 'vertical' ? '16px' : '0')};
        flex-direction: ${({ orientation }) => (orientation === 'vertical' ? 'column' : 'row')};

        &__slider {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          margin: 0 10px;

          .ant-slider {
            width: ${({ orientation }) => (orientation === 'vertical' ? '12px' : '100%')};
          }
        }

        .ant-btn {
          padding: 0;
          width: 32px;
          height: 32px;
          min-width: 32px;
          min-height: 32px;
        }
      }
    }
  }
`;
