import { useCallback, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { isTokenValid } from '@src/utils/auth';
import { getStringFromLocalStorage, removeFromLocalStorage, saveStringToLocalStorage } from '@src/utils/local-storage';

export const useAuth = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(getStringFromLocalStorage({ key: 'accessToken' }));

  useEffect(() => {
    const token = getStringFromLocalStorage({ key: 'accessToken' });
    const isValid = isTokenValid(token);

    if (!isValid) {
      removeFromLocalStorage({ key: 'accessToken' });
    }

    setIsAuthenticated(isValid);
  }, []);

  const userSignIn = useCallback(
    ({ token, redirectUrl }) => {
      saveStringToLocalStorage({ key: 'accessToken', value: token });
      setIsAuthenticated(true);

      navigate(redirectUrl);
    },
    [navigate],
  );

  const userSignOut = useCallback(() => {
    removeFromLocalStorage({ key: 'accessToken' });
    setIsAuthenticated(false);

    navigate('/');
  }, [navigate]);

  return { isAuthenticated, userSignIn, userSignOut };
};
