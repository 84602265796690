import styled from 'styled-components';

export const StyledSideMenu = styled.div`
  .side-menu {
    position: fixed;
    z-index: 5;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: all ease 0.8s;

    &__content {
      background-color: ${({ theme }) => theme.colors.codGray};
      width: 450px;
      margin-right: auto;
      padding: 120px 30px;
      height: 100%;
      overflow-y: auto;
      position: relative;
      left: -500px;
      cursor: auto;
      transition-delay: 1s;
      transition: left ease 1s;

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.1);
        background-color: ${({ theme }) => theme.colors.raven};
      }

      &::-webkit-scrollbar {
        width: 2px;
        background-color: ${({ theme }) => theme.colors.raven};
      }

      &__close-button {
        display: inline-block;
        border: 2px solid;
        width: 50px;
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        padding: 0;
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 50%;
        transform: rotate(0);
        transition: all ease 0.4s;

        &:hover {
          color: ${({ theme }) => theme.colors.mainOrange};
          border-color: ${({ theme }) => theme.colors.mainOrange};
          transform: rotate(90deg) scale(1.1);
        }
      }
    }

    &--is-open {
      opacity: 1;
      visibility: visible;
      width: 100%;
      transition: all ease 0.8s;

      .side-menu__content {
        left: 0;
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media (max-width: 767px) {
    .side-menu {
      &__content {
        width: 320px;
        padding: 120px 30px;
      }
    }
  }

  @media screen and (min-width: 1050px) {
    .side-menu {
      display: none;
    }
  }
`;
