import styled from 'styled-components';

export const StyledPersonalInformationForm = styled.div`
  width: 100%;

  .personal-information-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

    &__submit {
      margin-top: 40px;
      display: flex;
      justify-content: center;
    }
  }
`;
