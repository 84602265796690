import styled from 'styled-components';

const StyledMenuIcon = styled.div`
  .menu-icon {
    color: ${({ theme }) => theme.colors.mainBlue};
    padding: 8px 0 0 0;
    transition: all 0.3s ease-in-out;
    cursor: pointer;

    &:hover {
      color: ${({ theme }) => theme.colors.mainOrange};
    }
  }
`;

export { StyledMenuIcon };
