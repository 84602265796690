import styled from 'styled-components';

export const StyledLoginForm = styled.div`
  width: 100%;

  .login-form {
    width: 100%;
    justify-content: center;

    &__item {
    }

    &__submit {
      margin-top: 40px;

      button {
        width: 100%;
      }
    }

    &__google {
      margin-top: 40px;
    }
  }
`;
