import styled from 'styled-components';

export const StyledSwiper = styled.div`
  --swiper-navigation-sides-offset: 6px;
  --swiper-theme-color: ${({ theme }) => theme.colors.mainBlue};

  .as-swiper {
    position: relative;
    padding: 0 40px;

    &__swiper {
      width: 100%;
      padding: 20px 0;
    }
  }
`;
