import React from 'react';

import { Form } from 'antd';
import { block } from 'bem-cn';
import { FaEnvelope, FaShieldAlt } from 'react-icons/fa';

import { FormInput } from '@src/components/atoms/form/form-input';
import { GoogleSignIn } from '@src/components/molecules/google/google-sign-in';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { useNotificationContext } from '@src/components/molecules/notification-context';
import { Heading2 } from '@src/theme';
import { NOTIFICATION_TYPES } from '@src/utils/constants';

import { StyledLoginForm } from './styles';

const b = block('login-form');

const LoginForm = ({ onSignIn, onGoogleSignIn, loading }) => {
  const [form] = Form.useForm();
  const notificationApi = useNotificationContext();

  const handleFailure = () => {
    notificationApi[NOTIFICATION_TYPES.error]({
      message: 'Google sign in failed. Please try again.',
      pauseOnHover: true,
    });
  };

  return (
    <StyledLoginForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onSignIn}
        initialValues={{
          email: '',
          password: '',
        }}
      >
        <div className={b('item')}>
          <FormInput
            name="email"
            label="Email"
            rules={[
              { required: true, message: 'Please input your email!' },
              { type: 'email', message: 'The input is not valid E-mail!' },
            ]}
            icon={<FaEnvelope />}
            form={form}
          />
        </div>
        <div className={b('item')}>
          <FormInput
            name="password"
            label="Password"
            rules={[{ required: true, message: 'Please input your password!' }]}
            icon={<FaShieldAlt />}
            isPassword
            form={form}
          />
        </div>
        <div className={b('submit')}>
          <Form.Item>
            <GradientButton type={loading ? 'button' : 'submit'} loading={loading} width="auto">
              CONTINUE
            </GradientButton>
          </Form.Item>
        </div>

        <div className={b('google')}>
          <Heading2>Or</Heading2>
          <GoogleSignIn onSuccess={onGoogleSignIn} onError={handleFailure} />
        </div>
      </Form>
    </StyledLoginForm>
  );
};

export default LoginForm;
