import React, { useCallback, useState } from 'react';

import { Form } from 'antd';

import { FormInput } from '@src/components/atoms/form/form-input';
import { OptionPicker } from '@src/components/atoms/form/option-picker';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { bemClassName } from '@src/utils/bem';

import { StyledHealthInformationForm } from './styles';

const b = bemClassName('health-information-form');

const HealthInformationForm = ({ onSubmit, onBack, profileData, optionsData, loading }) => {
  const [form] = Form.useForm();

  const medicalConditions = profileData?.onboarding?.healthInformation?.medicalConditions;
  const allergiesOther = profileData?.onboarding?.healthInformation?.allergiesOther;

  const [showMedCondOther, setShowMedCondOther] = useState(medicalConditions && medicalConditions != '');
  const [showAllergiesOther, setShowAllergiesOther] = useState(allergiesOther && allergiesOther != '');

  const onFinish = useCallback(
    (values) => {
      onSubmit({
        onboarding: {
          ...profileData?.onboarding,
          finished: true,
          healthInformation: {
            medicalConditions: values.medicalConditions,
            medicalConditionsOther: values.medicalConditionsOther,
            allergies: values.allergies,
            allergiesOther: values.allergiesOther,
          },
        },
      });
    },
    [onSubmit, profileData?.onboarding],
  );

  return (
    <StyledHealthInformationForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          medicalConditions: profileData?.onboarding?.healthInformation?.medicalConditions || [],
          medicalConditionsOther: profileData?.onboarding?.healthInformation?.medicalConditionsOther || '',
          allergies: profileData?.onboarding?.healthInformation?.allergies || [],
          allergiesOther: profileData?.onboarding?.healthInformation?.allergiesOther || '',
        }}
      >
        <div className={b('item')}>
          <OptionPicker
            label="Medical Conditions"
            question="Do you have any medical conditions that we should be aware of to provide you with safe and suitable recipes?"
            name="medicalConditions"
            form={form}
            options={optionsData?.medicalConditions || []}
            multiple
            showOther
            hasOther={showMedCondOther}
            onOther={setShowMedCondOther}
            showNone
            onNone={() => {
              setShowMedCondOther(false);
              form.setFieldsValue({ medicalConditionsOther: '' });
            }}
          />
        </div>

        {showMedCondOther && (
          <div className={b('item')}>
            <FormInput
              name="medicalConditionsOther"
              label="Other Medical Condition"
              rules={[{ required: true, message: 'Please input your other medical condition!' }]}
              form={form}
            />
          </div>
        )}

        <div className={b('item')}>
          <OptionPicker
            label="Allergies"
            question="Do you have any food allergies?"
            name="allergies"
            form={form}
            options={optionsData?.allergies || []}
            multiple
            showOther
            hasOther={showAllergiesOther}
            onOther={setShowAllergiesOther}
            showNone
            onNone={() => {
              setShowAllergiesOther(false);
              form.setFieldsValue({ allergiesOther: '' });
            }}
          />
        </div>

        {showAllergiesOther && (
          <div className={b('item')}>
            <FormInput
              name="allergiesOther"
              label="Other Allergy"
              rules={[{ required: true, message: 'Please input your other allergy!' }]}
              form={form}
            />
          </div>
        )}

        <div className={b('submit')}>
          <Form.Item>
            <GradientButton variant={'secondary'} loading={loading} onClick={onBack}>
              BACK
            </GradientButton>
          </Form.Item>
          <Form.Item>
            <GradientButton type={loading ? 'button' : 'submit'} loading={loading}>
              FINISH
            </GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledHealthInformationForm>
  );
};

export default HealthInformationForm;
