import styled from 'styled-components';

export const StyledFlipDigitCard = styled.div`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  .flip-card {
    font-size: ${({ size }) => `${size || 4}rem`};
    position: relative;
    background-color: ${({ theme }) => theme.colors.whisper};
    color: ${({ theme }) => theme.colors.mainOrange};
    display: inline-flex;
    flex-direction: column;
    border-radius: 0.2em;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);
    perspective: 1000px;
    user-select: none;
    cursor: pointer;

    &__top,
    &__bottom {
      line-height: 1;
      height: 0.75em;
      padding: 0.25em;
      overflow: hidden;
    }

    &__top {
      border-top-left-radius: 0.2em;
      border-top-right-radius: 0.2em;
      background-color: ${({ theme }) => theme.colors.alabaster};
      border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
    }

    &__bottom {
      border-bottom-left-radius: 0.2em;
      border-bottom-right-radius: 0.2em;
      background-color: ${({ theme }) => theme.colors.white};
      display: flex;
      align-items: flex-end;
    }

    &--animate {
      perspective-origin: 50% 50%;
      perspective: 300px;

      &:before,
      &:after {
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
      }

      &:before,
      &:after {
        line-height: 1;
        height: 0.75em;
        padding: 0.25em;
        overflow: hidden;
      }

      &:before {
        border-top-left-radius: 0.2em;
        border-top-right-radius: 0.2em;
        background-color: ${({ theme }) => theme.colors.alabaster};
        border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
        position: absolute;
        content: '${({ current }) => current}';
        width: 100%;
        animation: flip-top 250ms ease-in;
        transform-origin: bottom;
        transform-style: preserve-3d;
        transform: rotateX(0deg);
        z-index: -1;
      }

      @keyframes flip-top {
        2% {
          z-index: 1;
        }
        99% {
          transform: rotateX(-90deg);
        }
        100% {
          transform: rotateX(0deg);
          z-index: -1;
        }
      }

      &:after {
        border-bottom-left-radius: 0.2em;
        border-bottom-right-radius: 0.2em;
        background-color: ${({ theme }) => theme.colors.white};
        display: flex;
        align-items: flex-end;
        position: absolute;
        bottom: 0;
        content: '${({ next }) => next}';
        width: 100%;
        animation:
          flip-bottom 250ms ease-out 250ms,
          bounce 500ms ease-out 500ms;
        transform-origin: top;
        transform-style: preserve-3d;
        transform: rotateX(90deg);
      }

      @keyframes flip-bottom {
        99% {
          transform: rotateX(0deg);
        }
        100% {
          transform: rotateX(90deg);
        }
      }

      @keyframes bounce {
        0% {
          transform: rotateX(0deg);
        }
        50% {
          transform: rotateX(35deg);
        }
        100% {
          transform: rotateX(0deg);
        }
      }
    }
  }
`;
