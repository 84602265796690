import React, { useCallback, useState } from 'react';

import Lottie from 'react-lottie';

const LottieHoverPlayer = ({ animationData, options, speed = 1 }) => {
  const [animationState, setAnimationState] = useState({
    isStopped: true,
    isPaused: false,
  });

  const handleMouseEnter = useCallback(() => {
    setAnimationState({ isStopped: false, isPaused: false });
  }, []);

  const handleMouseLeave = useCallback(() => {
    setAnimationState({ isStopped: true, isPaused: false });
  }, []);

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <Lottie
        options={{ ...options, autoplay: false, animationData }}
        isStopped={animationState.isStopped}
        isPaused={animationState.isPaused}
        speed={speed}
      />
    </div>
  );
};

export default React.memo(LottieHoverPlayer);
