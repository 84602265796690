import styled from 'styled-components';

const StyledRingIcon = styled.div`
  padding: 0px;
  cursor: pointer;

  svg {
    animation: rotate-animation 1s linear infinite;
    transform: rotate(360deg);
  }

  @keyframes rotate-animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

export { StyledRingIcon };
