import styled from 'styled-components';

export const StyledMainMenu = styled.div`
  width: 100%;
  z-index: 2;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};

  .main-menu {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};

    &__logo-container,
    &__right-section {
      width: 244px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__logo-container {
      img {
        max-width: 84%;
        max-height: 84%;
        cursor: pointer;
      }
    }

    &__center-section {
      flex: 1;
      height: 100%;
      position: relative;

      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        height: 100%;
        width: 1px;
        background: ${({ theme }) => theme.colors.mercury};
      }

      &:before {
        left: 2px;
        transform: rotate(-10deg);
      }

      &:after {
        right: 2px;
        transform: rotate(10deg);
      }

      &__top {
        padding: 0 16px;
        height: 40%;
        border-bottom: 1px solid ${({ theme }) => theme.colors.mercury};
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;

        &__contact {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: nowrap;
          gap: 16px;

          svg {
            height: 16px;
            margin-right: 4px;
          }

          p {
            display: flex;
            align-items: center;
          }
        }

        &__social {
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          gap: 16px;

          &__links {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;
            gap: 16px;

            p {
              display: flex;
              align-items: center;
            }
          }
        }
      }

      &__bottom {
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &__right-section {
    }
  }

  @media (max-width: 1200px) {
    .main-menu {
      &__logo-container,
      &__right-section {
        width: 180px;
      }
    }
  }

  @media (max-width: 800px) {
  }
`;
