import styled from 'styled-components';

export const StyledHorizontalMenu = styled.div`
  width: 100%;

  .horizontal-menu {
    gap: 64px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      text-transform: uppercase;
    }

    &__item {
      text-align: center;

      p {
        margin: 0;
      }
    }
  }

  @media (max-width: 1150px) {
    .horizontal-menu {
      gap: 50px;
    }
  }
`;
