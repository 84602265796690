import React from 'react';

import { GoogleLogin } from '@react-oauth/google';

import { bemClassName } from '@src/utils/bem';

import { StyledGoogleSignIn } from './styles';

const b = bemClassName('google-sign-in');

const GoogleSignIn = ({ onSuccess, onError }) => {
  return (
    <StyledGoogleSignIn>
      <div className={b()}>
        <GoogleLogin onSuccess={onSuccess} onError={onError} width="304" text="continue_with" />
      </div>
    </StyledGoogleSignIn>
  );
};

export default GoogleSignIn;
