import React from 'react';

import { TextLinkColor } from '@src/components/atoms/text-link-color';
import { bemClassName } from '@src/utils/bem';

import { StyledIconTextLink } from './styles';

const b = bemClassName('icon-text-link');

const IconTextLink = ({ icon, text, link, color }) => {
  return (
    <StyledIconTextLink color={color}>
      <div className={b()}>
        {icon}
        <TextLinkColor className={b('link')} to={link}>
          {text}
        </TextLinkColor>
      </div>
    </StyledIconTextLink>
  );
};

export default IconTextLink;
