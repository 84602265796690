import styled from 'styled-components';

export const StyledSimpleRoundedPaginator = styled.div`
  .simple-rounded-paginator {
    display: flex;
    padding-left: 0;
    list-style: none;

    &__item {
      margin: 0 3px;
    }
  }
`;
