import React from 'react';

import { Image } from 'antd';

import defaultImage from '@src/theme/assets/images/default.png';

import { StyledHoverScaleImage } from './styles';

const HoverScaleImage = ({ source, width, height }) => {
  return (
    <StyledHoverScaleImage width={width} height={height}>
      <Image
        src={source || defaultImage}
        width={width}
        height={height}
        placeholder={<img src={defaultImage} alt="loading" />}
        fallback={defaultImage}
        alt="Hover scale image"
      />
    </StyledHoverScaleImage>
  );
};

export default HoverScaleImage;
