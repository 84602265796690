import styled from 'styled-components';

import SignInFormBackground from '@src/theme/assets/backgrounds/abstract-white-bg.webp';

export const StyledLoginModal = styled.div`
  .login-modal {
    &__body {
      display: flex;

      &__left-column,
      &__right-column {
        display: flex;
      }

      &__left-column {
        width: 54%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        background-image: url(${SignInFormBackground});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-bottom: 80px;

        &__animation {
          width: 200px;
        }
      }

      &__right-column {
        width: 46%;
        display: flex;
        flex-direction: column;
        padding: 120px 32px;
        gap: 10px;
        text-align: center;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.colors.white};
      }

      &__google-reg-column {
        display: flex;
        flex-direction: column;
        gap: 25px;

        &__buttons {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 25px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .login-modal {
      &__body {
        &__right-column {
          width: 100%;
          padding: 10px 12px 40px 12px;

          &__animation {
            max-width: 200px;
          }
        }
      }
    }
  }
`;
