import React from 'react';

import { bemClassName } from '@src/utils/bem';

import { StyledLoadingIndicator } from './styles';
import { RingIcon } from '../icons/ring-icon';

const b = bemClassName('atom-loading');

const LoadingIndicator = ({ size }) => {
  return (
    <StyledLoadingIndicator size={size}>
      <div className={b()}>
        <div className={b('ring')}>
          <RingIcon size={size} />
        </div>
        <div className={b('ring')}>
          <RingIcon size={size} />
        </div>
      </div>
    </StyledLoadingIndicator>
  );
};

export default LoadingIndicator;
