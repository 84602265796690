import styled from 'styled-components';

export const StyledIconTextLinkRounded = styled.div`
  width: fit-content;

  .icon-text-link-rounded {
    display: flex;
    align-items: center;
    gap: 7px;
    text-transform: uppercase;
    cursor: pointer;

    &__link {
      padding-bottom: 24px;
    }

    &:hover > div {
      color: ${({ theme }) => theme.colors.white};
      background: ${({ theme }) => theme.colors.mainOrange};
    }

    &:hover a {
      background-size: 100% 3px;
      color: ${({ theme, hoverColor }) => (hoverColor ? hoverColor : theme.colors.mainOrange)};
    }
  }
`;
