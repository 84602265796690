export const variables = {
  // ------------------------------------------------------------------------------------------------------
  // Colors
  // ------------------------------------------------------------------------------------------------------
  colors: {
    black: '#000000', // -------------- Black
    white: '#ffffff', // -------------- White
    error: '#e10000', // -------------- Error
    transparent: 'transparent', // ---- Transparent
    mainBlue: '#1F4575', // ----------- Dark blue
    mainOrange: '#D95725', // --------- Dark orange
    codGray: '#1C1C1C', // ------------ Very dark gray
    mineShaft: '#2B2B2B', // ---------- Dark gray
    riverBed: '#404d5b', // ----------- Dark bluish-gray
    slateGray: '#6a7989', // ---------- Medium dark gray-blue
    raven: '#7B7E86', // -------------- Medium gray-blue
    osloGray: '#7f8994', // ----------- Light gray-blue
    alto: '#D9D9D9', // --------------- Light gray
    mercury: '#E3E3E3', // ------------ Very light gray
    iron: '#E2E2E4', // --------------- Light gray with a hint of blue
    gallery: '#EDEDED', // ------------ Very light gray
    athensGray: '#F0F2F4', // --------- Very light gray with a hint of blue
    whisper: '#F2F1F7', // ------------ Very light grayish-purple
    concrete: '#F3F3F3', // ----------- Very light gray
    wildSand: '#F4F4F4', // ----------- Very light gray
    alabaster: '#F7F7F7', // ---------- Very light gray
    havelockBlue: '#4A90E2', // ------- Light blue
    lima: '#7ED321', // --------------- Light green
    ripeLemon: '#F8E71C', // ---------- Light yellow
    buttercup: '#F5A623', // ---------- Medium light yellow
    monza: '#D0021B', // -------------- Light red
    redBerry: '#8B0000', // ----------- Dark red

    // ------------------------------------------------------------------------------------------------------
    // Transparent colors
    // ------------------------------------------------------------------------------------------------------
    getTransparentColor: (color, opacity) => {
      const hexToRgb = (hex) => {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return `${r}, ${g}, ${b}`;
      };

      return `rgba(${hexToRgb(color)}, ${opacity})`;
    },
  },
};
