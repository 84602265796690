import React, { useCallback } from 'react';

import { Form } from 'antd';

import { RangePicker } from '@src/components/atoms/form/range-picker';
import { LottiePlayer } from '@src/components/atoms/lottie-player';
import { GradientButton } from '@src/components/molecules/gradient-button';
import { activity, glass, sleep } from '@src/theme/assets/animations';
import { bemClassName } from '@src/utils/bem';
import { lottieDefaultOptions } from '@src/utils/settings';

import { StyledLifeStyleHabitsForm } from './styles';

const b = bemClassName('life-style-habits-form');

const LifeStyleHabitsForm = ({ onSubmit, onBack, profileData, loading }) => {
  const [form] = Form.useForm();

  const onFinish = useCallback(
    (values) => {
      onSubmit({
        onboarding: {
          ...profileData?.onboarding,
          onboardingStep: 5,
          currentLifestyleAndHabits: {
            currentActivityLevel: values.currentActivityLevel,
            waterIntake: values.waterIntake,
            sleepPatterns: values.sleepPatterns,
          },
        },
      });
    },
    [onSubmit, profileData?.onboarding],
  );

  return (
    <StyledLifeStyleHabitsForm>
      <Form
        className={b()}
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{
          currentActivityLevel: profileData?.onboarding?.currentLifestyleAndHabits?.currentActivityLevel,
          waterIntake: profileData?.onboarding?.currentLifestyleAndHabits?.waterIntake,
          sleepPatterns: profileData?.onboarding?.currentLifestyleAndHabits?.sleepPatterns,
        }}
      >
        <div className={b('item')}>
          <div className={b('item', 'lottie')}>
            <LottiePlayer animationData={activity} options={lottieDefaultOptions} speed={1.2} />
          </div>
          <RangePicker
            name="currentActivityLevel"
            form={form}
            orientation="horizontal"
            labelPosition="bottom"
            steps={[
              'Sedentary (little or no exercise)',
              'Lightly active (light exercise/sports 1-3 days/week)',
              'Moderately active (moderate exercise/sports 3-5 days/week)',
              'Very active (hard exercise/sports 6-7 days a week)',
              'Super active (very hard exercise/sports & physical job)',
            ]}
          />
        </div>

        <div className={b('item')}>
          <div className={b('item', 'lottie')}>
            <LottiePlayer animationData={glass} options={lottieDefaultOptions} speed={1.2} />
          </div>
          <RangePicker
            name="waterIntake"
            form={form}
            orientation="horizontal"
            labelPosition="bottom"
            steps={[
              'Less than 1 liter per day',
              '1-2 liters per day',
              '2-3 liters per day',
              'More than 3 liters per day',
            ]}
          />
        </div>

        <div className={b('item')}>
          <div className={b('item', 'lottie')}>
            <LottiePlayer animationData={sleep} options={lottieDefaultOptions} speed={1.2} />
          </div>
          <RangePicker
            name="sleepPatterns"
            form={form}
            orientation="horizontal"
            labelPosition="bottom"
            steps={[
              'Less than 5 hours per night',
              '5-6 hours per night',
              '6-7 hours per night',
              '7-8 hours per night',
              'More than 8 hours per night',
            ]}
          />
        </div>

        <div className={b('submit')}>
          <Form.Item>
            <GradientButton variant={'secondary'} loading={loading} onClick={onBack}>
              BACK
            </GradientButton>
          </Form.Item>
          <Form.Item>
            <GradientButton type={loading ? 'button' : 'submit'} loading={loading}>
              NEXT
            </GradientButton>
          </Form.Item>
        </div>
      </Form>
    </StyledLifeStyleHabitsForm>
  );
};

export default LifeStyleHabitsForm;
